<template>
    <div class="admin-promo">
        <div class="promo-content">
            <div class="content-header">
                <div class="header-element element-code">CODE</div>
                <div class="header-element element-reward">REWARD</div>
                <div class="header-element element-redeemptions">REDEEMPTIONS</div>
                <div class="header-element element-level">MIN LEVEL</div>
                <div class="header-element element-actions">ACTIONS</div>
            </div>
            <div class="content-list">
                <transition name="fade" mode="out-in">
                    <div v-if="adminPromoList.data === null || adminPromoList.loading === true" class="list-loading" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else-if="adminPromoList.data.length > 0" class="list-data" key="data">

                        <AdminPromoElement v-for="promo in adminPromoList.data" v-bind:key="promo._id" v-bind:promo="promo" />

                    </div>
                    <div v-else class="list-empty" key="empty">NO PROMO FOUND.</div>
                </transition>
            </div>
        </div>

        <Pagination v-on:setPage="adminSetPage" v-bind:page="adminPromoList.page" v-bind:count="adminPromoList.count" countPage="14" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import Pagination from '@/components/Pagination';
    import AdminPromoElement from '@/components/admin/promo/AdminPromoElement';

    export default {
        components: {
            LoadingAnimation,
            Pagination,
            AdminPromoElement
        },
        methods: {
            ...mapActions([
                'adminGetPromoListSocket', 
                'adminSetPromoListPage', 
                'adminSetFilterSearch'
            ]),
            adminSetPage(page) {
                this.adminSetPromoListPage(page);
                this.adminGetPromoListSocket({ page: this.adminPromoList.page, search: this.adminFilterSearch });
            }
        },
        computed: {
            ...mapGetters([
                'adminPromoList', 
                'adminFilterSearch'
            ])
        },
        created() {
            if(this.adminPromoList.loading === false) {
                this.adminGetPromoListSocket({ page: this.adminPromoList.page, search: this.adminFilterSearch });
            }
        },
        beforeRouteLeave(to, from, next) {
            this.adminSetFilterSearch('');
            next();
        }
    }
</script>

<style scoped>
    .admin-promo {
        width: 100%;
    }

    .admin-promo .promo-content {
        width: 100%;
        padding: 15px;
        border-radius: 8px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .admin-promo .content-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .admin-promo .header-element {
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-promo .header-element.element-code,
    .admin-promo .header-element.element-reward,
    .admin-promo .header-element.element-redeemptions,
    .admin-promo .header-element.element-level {
        width: 22.5%;
    }

    .admin-promo .header-element.element-actions {
        width: 10%;
    }

    .admin-promo .header-element.element-actions {
        display: flex;
        justify-content: flex-end;
    }

    .admin-promo .header-element.element-date {
        width: 20%;
    }

    .admin-promo .content-list {
        width: 100%;
        margin-top: 8px;
    }

    .admin-promo .list-loading {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-promo .list-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .admin-promo .list-loading.fade-leave-to {
        opacity: 0;
    }

    .admin-promo .list-data {
        width: 100%;
    }

    .admin-promo .list-empty {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-promo .list-data.fade-enter-active,
    .admin-promo .list-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .admin-promo .list-data.fade-enter-from,
    .admin-promo .list-empty.fade-enter-from {
        opacity: 0;
    }

    @media only screen and (max-width: 1200px) {

        .admin-promo .codes-content {
            padding: 10px;
        }

        .admin-promo .content-header {
            display: none;
        }

        .admin-promo .content-list {
            width: 100%;
            margin-top: 0;
        }

    }
</style>