<template>
    <div class="admin-box-element">
        <div class="element-section section-name">
            <div class="section-title">NAME</div>
            <div class="section-content">
                {{box.name}}
            </div>
        </div>
        <div class="element-section section-amount">
            <div class="section-title">AMOUNT</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value">
                    <span>{{generalFormatAmount(box.amount).split('.')[0]}}</span>.{{generalFormatAmount(box.amount).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-categories">
            <div class="section-title">CATEGORIES</div>
            <div class="section-content">
                {{ box.categories.join(', ') }}
            </div>
        </div>
        <div class="element-section section-state" v-bind:class="'section-' + box.state">
            <div class="section-title">STATE</div>
            <div class="section-content">
                {{box.state.toUpperCase()}}
            </div>
        </div>
        <div class="element-section section-actions">
            <div class="section-title">ACTIONS</div>
            <div class="section-content">
                <router-link v-bind:to="'/cases/' + box._id" class="link-view">VIEW</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'AdminBoxElement',
        mixins: [
            mixins
        ],
        props: [
            'box'
        ],
        methods: {
            ...mapActions([
                'adminSendBoxPauseSocket'
            ]),
            adminPauseButton() {
                this.adminSendBoxPauseSocket({ boxId: this.box._id });
            },
            adminViewButton() {
                
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading'
            ])
        }
    }
</script>

<style scoped>
    .admin-box-element {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .admin-box-element:nth-child(odd) {
        border-radius: 8px;
        background: #1d212d;
    }

    .admin-box-element .element-section {
        display: flex;
        flex-direction: column;
    }

    .admin-box-element .element-section.section-name,
    .admin-box-element .element-section.section-amount,
    .admin-box-element .element-section.section-state {
        width: 20%;
    }

    .admin-box-element .element-section.section-categories {
        width: 25%;
    }

    .admin-box-element .element-section.section-actions {
        width: 15%;
    }

    .admin-box-element .section-title {
        display: none;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-box-element .section-content {
        display: flex;
        align-items: center;
    }

    .admin-box-element .element-section.section-name .section-content,
    .admin-box-element .element-section.section-state .section-content,
    .admin-box-element .element-section.section-categories .section-content {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-box-element .element-section.section-state.section-active .section-content {
        color: #00c74d;
    }

    .admin-box-element .element-section.section-state.section-paused .section-content {
        color: #fd3b31;
    }

    .admin-box-element .element-section.section-amount .section-content img {
        width: 21px;
        margin-right: 10px;
    }

    .admin-box-element .element-section.section-actions .section-content {
        justify-content: flex-end;
    }

    .admin-box-element .content-value {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .admin-box-element .content-value span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-box-element a.link-view {
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 12px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
        transition: background 0.3s ease;
    }

    .admin-box-element a.link-view:hover {
        background: #00de56;
    }

    @media only screen and (max-width: 1200px) {

        .admin-box-element {
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 20px;
        }

        .admin-box-element .element-section {
            width: 100%!important;
            margin-top: 10px;
        }

        .admin-box-element .element-section.section-name {
            margin-top: 0;
        }

        .admin-box-element .element-section.section-actions {
            align-items: flex-start;
        }

        .admin-box-element .section-title {
            display: block;
        }

        .admin-box-element .section-content {
            margin-top: 5px;
        }

    }
</style>