<template>
    <button v-on:click="adminItemButton()" v-bind:class="['admin-box-item-element', { 
        'element-selected': adminBoxList.selected.some((element) => element._id === item._id) === true 
    }]">
        <div class="element-image">
            <img v-bind:src="item.image" alt="item-image" />
        </div>
        <div class="element-name">{{ item.name }}</div>
        <div class="element-amount">
            <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
            <div class="amount-value">
                <span>{{generalFormatAmount(item.amountFixed).split('.')[0]}}</span>.{{generalFormatAmount(item.amountFixed).split('.')[1]}}
            </div>
        </div>
    </button>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'AdminBoxItemElement',
        mixins: [
            mixins
        ],
        props: [
            'item'
        ],
        methods: {
            ...mapActions([
                'adminAddBoxListSelected',
                'adminRemoveBoxListSelected'
            ]),
            adminItemButton() {
                if(this.adminBoxList.selected.some((element) => element._id === this.item._id) === true) { 
                    this.adminRemoveBoxListSelected(this.item); 
                } else { this.adminAddBoxListSelected(this.item); } 
            }
        },
        computed: {
            ...mapGetters([
                'adminBoxList'
            ])
        }
    }
</script>

<style scoped>
    button.admin-box-item-element {
        width: calc(14.28% - 8.57px);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        margin-right: 10px;
        padding: 15px;
        border-radius: 8px;
        background: #12161d;
        cursor: pointer;
    }

    button.admin-box-item-element:nth-child(7n) {
        margin-right: 0;
    }

    button.admin-box-item-element::before {
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        border-radius: 8px;
        transition: all 0.3s ease;
    }

    button.admin-box-item-element.element-selected::before {
        border: 1px solid #fd3b31;
    }

    button.admin-box-item-element .element-image {

    }

    button.admin-box-item-element .element-image img {
        width: 84px;
        height: 84px;
    }

    button.admin-box-item-element .element-name {
        width: 100%;
        margin-top: 8px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: 700;
        color: #596076;
    }

    button.admin-box-item-element .element-amount {
        width: 100%;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6px;
        border-radius: 5px;
        background: #212732;
    }

    button.admin-box-item-element .element-amount img {
        width: 21px;
        margin-right: 10px;
    }

    button.admin-box-item-element .amount-value {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    button.admin-box-item-element .amount-value span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    @media only screen and (max-width: 1350px) {

        button.admin-box-item-element {
            width: calc(16.66% - 8.33px);
        }

        button.admin-box-item-element:nth-child(7n) {
            margin-right: 10px;
        }

        button.admin-box-item-element:nth-child(6n) {
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 1200px) {

        button.admin-box-item-element {
            width: calc(20% - 8px);
        }

        button.admin-box-item-element:nth-child(6n) {
            margin-right: 10px;
        }

        button.admin-box-item-element:nth-child(5n) {
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 1050px) {

        button.admin-box-item-element {
            width: calc(25% - 7.5px);
        }

        button.admin-box-item-element:nth-child(5n) {
            margin-right: 10px;
        }

        button.admin-box-item-element:nth-child(4n) {
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 600px) {

        button.admin-box-item-element {
            width: calc(33.33% - 6.66px);
        }

        button.admin-box-item-element:nth-child(4n) {
            margin-right: 10px;
        }

        button.admin-box-item-element:nth-child(3n) {
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 500px) {

        button.admin-box-item-element {
            width: calc(50% - 5px);
        }

        button.admin-box-item-element:nth-child(3n) {
            margin-right: 10px;
        }

        button.admin-box-item-element:nth-child(2n) {
            margin-right: 0;
        }

    }
</style>