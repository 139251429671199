<template>
    <div class="admin-leaderboard-prize-element">
        <input v-model="prize.amount" v-on:input="adminValidateInput()" type="text" placeholder="ENTER PRIZE..." />
        <button v-on:click="$parent.adminRemoveButton(pos)" class="button-remove">
            <IconClose />
        </button>
    </div>
</template>

<script>
    import IconClose from '@/components/icons/IconClose';

    export default {
        name: 'AdminLeaderboardPrizeElement',
        components: {
            IconClose,
        },
        props: [
            'pos',
            'prize'
        ],
        methods: {
            adminValidateInput() {
                this.prize.amount = this.prize.amount.replace(',', '.').replace(/[^\d.]/g, '');
                this.prize.amount = this.prize.amount.indexOf('.') >= 0 ? this.prize.amount.substr(0, this.prize.amount.indexOf('.')) + '.' + this.prize.amount.substr((this.prize.amount.indexOf('.') + 1), 2).replace('.', '') : this.prize.amount;
            }
        }
    }
</script>

<style scoped>

    .admin-leaderboard-prize-element {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 8px;
    }

    .admin-leaderboard-prize-element input {
        width: calc(100% - 50px);
        height: 45px;
        margin-top: 5px;
        margin-right: 5px;
        padding: 0 0 0 10px;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 700;
        color: #ffffff;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .admin-leaderboard-prize-element input::placeholder {
        color: #596076;
    }

    .admin-leaderboard-prize-element button.button-remove {
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        border-radius: 5px;
        background: #fd3b31;
    }

    .admin-leaderboard-prize-element button.button-remove svg {
        fill: #ffffff;
    }

</style>