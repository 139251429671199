import { render, staticRenderFns } from "./AdminButtonClose.vue?vue&type=template&id=43ed23e0&scoped=true"
import script from "./AdminButtonClose.vue?vue&type=script&lang=js"
export * from "./AdminButtonClose.vue?vue&type=script&lang=js"
import style0 from "./AdminButtonClose.vue?vue&type=style&index=0&id=43ed23e0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43ed23e0",
  null
  
)

export default component.exports