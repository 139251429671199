<template>
    <div class="admin-user-overview">
        <div class="overview-info">
            <div class="info-avatar">
                <transition name="fade" mode="out-in">
                    <div v-if="adminUserData.data === null || adminUserData.loading === true" class="avatar-loading" key="loading"></div>
                    <AvatarImage v-else v-bind:image="adminUserData.data.avatar" key="content" />
                </transition>
            </div>
            <div class="info-username">
                <transition name="fade" mode="out-in">
                    <div v-if="adminUserData.data === null || adminUserData.loading === true" class="username-loading" key="loading"></div>
                    <div v-else class="username-value" key="content">
                        <span v-html="adminUserData.data.username"></span>
                        <span class="user-rank" v-bind:class="{ 'rank-mod': adminUserData.data.rank === 'mod', 'rank-admin': adminUserData.data.rank === 'admin' }">{{adminUserData.data.rank.toUpperCase()}}</span>
                    </div>
                </transition>
            </div>
        </div>
        <div class="overview-profile">
            <a v-bind:href="adminUserData.data !== null && adminUserData.loading === false ? 'https://steamcommunity.com/profiles/' + adminUserData.data.steamid : null" target="_blank" class="link-profile">VIEW STEAM PROFILE</a>
        </div>
        <div class="overview-actions">
            <button v-on:click="adminMuteButton" class="button-mute">
                <IconMute />
                MUTE USER
            </button>
            <button v-on:click="adminBanButton" class="button-ban">
                <IconUserTimes />
                BAN USER
            </button>
        </div>
        <div class="overview-settings">
            <div class="settings-element element-select">
                <div class="element-title">USER RANK</div>
                <div class="element-input">
                    <select v-model="adminRank">
                        <option value="user">USER</option>
                        <option value="mod">MOD</option>
                        <option value="admin">ADMIN</option>
                    </select>
                    <button v-on:click="adminRankButton" class="button-update">
                        <transition name="fade" mode="out-in">
                            <div class="button-loading" v-if="socketSendLoading === 'AdminUserRank'" key="loading">
                                <LoadingAnimation />
                            </div>
                            <div class="button-content" v-else key="content">UPDATE</div>
                        </transition>
                    </button>
                </div>
            </div>
            <div class="settings-element element-text">
                <div class="element-title">USER BALANCE</div>
                <div class="element-input">
                    <input v-model="adminBalance" v-on:input="adminValidateInput" type="text" placeholder="Enter balance..." />
                    <button v-on:click="adminBalanceButton" class="button-update">
                        <transition name="fade" mode="out-in">
                            <div class="button-loading" v-if="socketSendLoading === 'AdminUserBalance'" key="loading">
                                <LoadingAnimation />
                            </div>
                            <div class="button-content" v-else key="content">UPDATE</div>
                        </transition>
                    </button>
                </div>
            </div>
            <div class="settings-element element-text">
                <div class="element-title">USER AFFILIATE CODE</div>
                <div class="element-input">
                    <input v-model="adminCode" type="text" placeholder="Enter affiliate code..." />
                    <button v-on:click="adminCodeButton" class="button-update">
                        <transition name="fade" mode="out-in">
                            <div class="button-loading" v-if="socketSendLoading === 'AdminUserCode'" key="loading">
                                <LoadingAnimation />
                            </div>
                            <div class="button-content" v-else key="content">UPDATE</div>
                        </transition>
                    </button>
                </div>
            </div>
            <div class="settings-element element-toggle">
                <div class="element-title">USER WITHDRAW BLOCK</div>
                <div class="element-input">
                    <button v-on:click="adminBlockToggle('blockWithdraw', !adminGetBlockWithdraw)" class="button-switch" v-bind:class="{ 'button-active': adminGetBlockWithdraw === true }">
                        <span class="switch-slider"></span>
                    </button>
                </div>
            </div>
            <div class="settings-element element-toggle">
                <div class="element-title">USER RAIN BLOCK</div>
                <div class="element-input">
                    <button v-on:click="adminBlockToggle('blockRain', !adminGetBlockRain)" class="button-switch" v-bind:class="{ 'button-active': adminGetBlockRain === true }">
                        <span class="switch-slider"></span>
                    </button>
                </div>
            </div>
            <div class="settings-element element-toggle">
                <div class="element-title">USER TIP BLOCK</div>
                <div class="element-input">
                    <button v-on:click="adminBlockToggle('blockTip', !adminGetBlockTip)" class="button-switch" v-bind:class="{ 'button-active': adminGetBlockTip === true }">
                        <span class="switch-slider"></span>
                    </button>
                </div>
            </div>
            <div class="settings-element element-toggle">
                <div class="element-title">USER SPONSORSHIP BLOCK</div>
                <div class="element-input">
                    <button v-on:click="adminBlockToggle('blockSponsor', !adminGetBlockSponsor)" class="button-switch" v-bind:class="{ 'button-active': adminGetBlockSponsor === true }">
                        <span class="switch-slider"></span>
                    </button>
                </div>
            </div>
            <div class="settings-element element-toggle">
                <div class="element-title">USER LEADERBOARD BLOCK</div>
                <div class="element-input">
                    <button v-on:click="adminBlockToggle('blockLeaderboard', !adminGetBlockLeaderboard)" class="button-switch" v-bind:class="{ 'button-active': adminGetBlockLeaderboard === true }">
                        <span class="switch-slider"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconMute from '@/components/icons/IconMute';
    import IconUserTimes from '@/components/icons/IconUserTimes';
    import AvatarImage from '@/components/AvatarImage';
    import LoadingAnimation from '@/components/LoadingAnimation';

    export default {
        name: 'AdminUserOverview',
        components: {
            IconMute,
            IconUserTimes,
            AvatarImage,
            LoadingAnimation
        },
        data() {
            return {
                adminRank: null,
                adminBalance: null,
                adminCode: null
            }
        },
        methods: {
            ...mapActions([
                'notificationShow', 
                'modalsSetShow', 
                'modalsSetData', 
                'adminSendUserRankSocket', 
                'adminSendUserBalanceSocket', 
                'adminSendUserCodeSocket', 
                'adminSendUserBlockSocket'
            ]),
            adminValidateInput() {
                 this.adminBalance = this.adminBalance.replace(/[^\d.]/g, '');
            },
            adminRankButton() {
                if(this.adminUserData.data === null || this.socketSendLoading !== null) { return; }

                this.adminSendUserRankSocket({ userId: this.adminUserData.data._id, rank: this.adminRank });
            },
            adminBalanceButton() {
                if(this.adminUserData.data === null || this.socketSendLoading !== null) { return; }

                const balance = Math.floor(Number(this.adminBalance).toFixed(2) * 1000);

                if(balance === null || isNaN(balance) === true || balance < 0) {
                    this.notificationShow({type: 'error', message: 'Your entered balance is invalid.'});
                    return;
                }

                this.adminSendUserBalanceSocket({ userId: this.adminUserData.data._id, balance: balance });
            },
            adminCodeButton() {
                if(this.adminUserData.data === null || this.socketSendLoading !== null) { return; }

                if(this.adminCode === null || this.adminCode.trim() === '' || this.adminCode.trim().length <= 3) {
                    this.notificationShow({ type: 'error', message: 'Your entered affiliate code is invalid.' });
                    return;
                }

                this.adminSendUserCodeSocket({ userId: this.adminUserData.data._id, code: this.adminCode });
            },
            adminBlockToggle(block, value) {
                if(this.adminUserData.data === null || this.socketSendLoading !== null) { return; }

                this.adminSendUserBlockSocket({ userId: this.adminUserData.data._id, block: block, value: value });
            },
            adminMuteButton() {
                this.modalsSetData({ user: this.adminUserData.data });
                this.modalsSetShow('Mute');
            },
            adminBanButton() {
                this.modalsSetData({ user: this.adminUserData.data });
                this.modalsSetShow('Ban');
            }
        },
        computed: {
            ...mapGetters([
                'adminUserData', 
                'socketSendLoading'
            ]),
            adminGetBlockWithdraw() {
                return this.adminUserData.data !== null ? this.adminUserData.data.limits.blockWithdraw : false;
            },
            adminGetBlockRain() {
                return this.adminUserData.data !== null ? this.adminUserData.data.limits.blockRain : false;
            },
            adminGetBlockTip() {
                return this.adminUserData.data !== null ? this.adminUserData.data.limits.blockTip : false;
            },
            adminGetBlockSponsor() {
                return this.adminUserData.data !== null ? this.adminUserData.data.limits.blockSponsor : false;
            },
            adminGetBlockLeaderboard() {
                return this.adminUserData.data !== null ? this.adminUserData.data.limits.blockLeaderboard : false;
            }
        },
        watch: {
            'adminUserData.data': {
                handler(data, oldData)  {
                    if(this.adminUserData.data !== null) {
                        this.adminRank = this.adminUserData.data.rank;
                        this.adminBalance = parseFloat(Math.floor(this.adminUserData.data.balance / 10) / 100).toFixed(2);
                        this.adminCode = this.adminUserData.data.affiliates.code !== undefined ? this.adminUserData.data.affiliates.code : null;
                    }
                },
                deep: true
            }
        },
        created() {
            if(this.adminUserData.data !== null) {
                this.adminRank = this.adminUserData.data.rank;
                this.adminBalance = parseFloat(Math.floor(this.adminUserData.data.balance / 10) / 100).toFixed(2);
                this.adminCode = this.adminUserData.data.affiliates.code !== undefined ? this.adminUserData.data.affiliates.code : null;
            }
        }
    }
</script>

<style scoped>
    .admin-user-overview {
        width: 500px;
        padding: 37px 0 15px 0;
        border-radius: 8px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .admin-user-overview .overview-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 25px;
    }

    .admin-user-overview .info-avatar {
        height: 100px;
    }

    .admin-user-overview .info-avatar .avatar-image {
        width: 100px;
        height: 100px;
        border-radius: 12px;
    }

    .admin-user-overview .avatar-loading {
        width: 100px;
        height: 100px;
        position: relative;
        border-radius: 12px;
        background: #5f6779;
        overflow: hidden;
    }

    .admin-user-overview .avatar-loading::after {
        width: 100%;
        height: 100%;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        animation-name: loading_animation;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        background: linear-gradient(to right, #ffffff00 0%, rgba(255, 255, 255, .2) 50%, #ffffff00 100%);
        z-index: 1;
    }

    .admin-user-overview .avatar-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .admin-user-overview .avatar-loading.fade-leave-to {
        opacity: 0;
    }

    .admin-user-overview .info-username {
        margin-top: 18px;
    }

    .admin-user-overview .username-loading {
        width: 100px;
        height: 20px;
        position: relative;
        margin-top: 14px;
        border-radius: 3px;
        background: #5f6779;
        overflow: hidden;
    }

    .admin-user-overview .username-loading::after {
        width: 100%;
        height: 100%;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        animation-name: loading_animation;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        background: linear-gradient(to right, #ffffff00 0%, rgba(255, 255, 255, .2) 50%, #ffffff00 100%);
        z-index: 1;
    }

    .admin-user-overview .username-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .admin-user-overview .username-loading.fade-leave-to {
        opacity: 0;
    }

    .admin-user-overview .username-value {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-user-overview .username-value span.user-rank {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .admin-user-overview .username-value span.user-rank.rank-mod {
        color: #00c74d;
    }

    .admin-user-overview .username-value span.user-rank.rank-admin {
        color: #fd3b31;
    }

    .admin-user-overview .overview-profile {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 22px;
        padding: 0 25px;
    }

    .admin-user-overview a.link-profile {
        height: 35px;
        display: flex;
        align-items: center;
        margin-right: 12px;
        padding: 0 16px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        border-bottom: 2px solid #191e27;
    }

    .admin-user-overview a.link-profile:hover {
        color: #ffffff;
    }

    .admin-user-overview .overview-actions {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 12px;
        padding: 0 25px;
    }

    .admin-user-overview button.button-mute,
    .admin-user-overview button.button-ban {
        height: 35px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        border-bottom: 2px solid #191e27;
    }

    .admin-user-overview button.button-mute {
        margin-right: 12px;
    }

    .admin-user-overview button.button-mute:hover,
    .admin-user-overview button.button-ban:hover {
        color: #ffffff;
    }

    .admin-user-overview button.button-mute svg,
    .admin-user-overview button.button-ban svg {
        width: 15px;
        margin-right: 8px;
        fill: #767c8b;
        transition: fill 0.3s ease;
    }

    .admin-user-overview button.button-mute:hover svg,
    .admin-user-overview button.button-ban:hover svg {
        fill: #ffffff;
    }

    .admin-user-overview .overview-settings {
        width: 100%;
        margin-top: 31px;
        padding: 15px 15px 0 15px;
        border-top: 1px solid #212732;
    }

    .admin-user-overview .settings-element {
        width: 100%;
        margin-top: 10px;
        padding: 10px;
        border-radius: 5px;
        background: #212732;
    }

    .admin-user-overview .settings-element:first-of-type {
        margin-top: 0;
    }

    .admin-user-overview .settings-element.element-toggle {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .admin-user-overview .element-title {
        padding: 0 4px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
    }

    .admin-user-overview .settings-element.element-text .element-input,
    .admin-user-overview .settings-element.element-select .element-input {
        width: 100%;
        position: relative;
        margin-top: 8px;
    }

    .admin-user-overview .settings-element.element-toggle .element-input {
        display: flex;
        align-items: center;
    }

    .admin-user-overview .element-input select,
    .admin-user-overview .element-input input {
        width: 100%;
        height: 54px;
        padding: 0 115px 0 17px;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 700;
        color: #ffffff;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .admin-user-overview .element-input input::placeholder {
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .admin-user-overview .element-input button.button-update {
        width: 100px;
        height: 41px;
        position: absolute;
        top: 50%;
        right: 6px;
        transform: translate(0, -50%);
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #fd3b31;
        border-bottom: 2px solid #97302b;
    }

    .admin-user-overview .element-input button.button-update:hover {
        background: #fe524a;
    }

    .admin-user-overview .element-input button.button-update .button-loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-user-overview .element-input button.button-update .button-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .admin-user-overview .element-input button.button-update .button-loading.fade-leave-to {
        opacity: 0;
    }

    .admin-user-overview .element-input button.button-update .button-content.fade-enter-active {
        transition: opacity 0.5s;
    }

    .admin-user-overview .element-input button.button-update .button-content.fade-enter-from {
        opacity: 0;
    }

    .admin-user-overview .element-input button.button-switch {
        width: 56px;
        height: 30px;
        position: relative;
        border-radius: 34px;
        background: rgba(253, 59, 49, 0.4);
    }

    .admin-user-overview .element-input button.button-switch.button-active {
        background: rgba(0, 199, 77, 0.4);
    }

    .admin-user-overview .element-input button.button-switch .switch-slider {
        width: 22px;
        height: 22px;
        position: absolute;
        left: 4px;
        bottom: 4px;
        border-radius: 50%;
        background: #fd3b31;
        transition: all 0.3s ease;
    }

    .admin-user-overview .element-input button.button-switch.button-active .switch-slider {
        transform: translateX(26px);
        background: #00c74d;
    }

    @keyframes loading_animation {
        0% { transform: translateX(-100%); }
        50% { transform: translateX(100%); }
        100% { transform: translateX(100%); }
    }

    @media only screen and (max-width: 1100px) {

        .admin-user-overview {
            width: 100%;
        }

    }
</style>
