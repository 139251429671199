<template>
    <div class="admin-code-element">
        <div class="element-section section-name">
            <div class="section-title">NAME</div>
            <div class="section-content">
                {{code.name}}
            </div>
        </div>
        <div class="element-section section-reward">
            <div class="section-title">REWARD</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value">
                    <span>{{generalFormatAmount(code.reward).split('.')[0]}}</span>.{{generalFormatAmount(code.reward).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-redeemptions">
            <div class="section-title">REDEEMPTIONS</div>
            <div class="section-content">
                {{code.totalRedeemptions}}/{{code.maxRedeemptions}}
            </div>
        </div>
        <div class="element-section section-date">
            <div class="section-title">DATE</div>
            <div class="section-content">
                {{ new Date(code.createdAt).toLocaleString('en-US', { hour12: true }) }}
            </div>
        </div>
        <div class="element-section section-actions">
            <div class="section-title">ACTIONS</div>
            <div class="section-content">
                <button v-on:click="adminCopyButton(code.code.substring(0, 4) + '-' + code.code.substring(4, 8) + '-' + code.code.substring(8, 12) + '-' + code.code.substring(12, 16))" class="button-copy">COPY</button>
                <button v-on:click="adminRemoveButton()" class="button-remove" v-bind:disabled="socketSendLoading !== null">REMOVE</button>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'AdminCodeElement',
        mixins: [
            mixins
        ],
        props: [
            'code'
        ],
        methods: {
            ...mapActions([
                'notificationShow',
                'adminSendCodeRemoveSocket'
            ]),
            adminCopyButton(value) {
                this.generalCopyValue(value);
                this.notificationShow({ type: 'success', message: 'Copied to your clipboard.' });
            },
            adminRemoveButton() {
                this.adminSendCodeRemoveSocket({ codeId: this.code._id });
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading'
            ])
        }
    }
</script>

<style scoped>
    .admin-code-element {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .admin-code-element:nth-child(odd) {
        border-radius: 8px;
        background: #1d212d;
    }

    .admin-code-element .element-section {
        display: flex;
        flex-direction: column;
    }

    .admin-code-element .element-section.section-name {
        width: 30%;
    }

    .admin-code-element .element-section.section-reward,
    .admin-code-element .element-section.section-redeemptions,
    .admin-code-element .element-section.section-actions {
        width: 15%;
    }

    .admin-code-element .element-section.section-date {
        width: 20%;
    }

    .admin-code-element .section-title {
        display: none;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-code-element .section-content {
        display: flex;
        align-items: center;
    }

    .admin-code-element .element-section.section-name .section-content,
    .admin-code-element .element-section.section-redeemptions .section-content,
    .admin-code-element .element-section.section-date .section-content {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-code-element .element-section.section-actions .section-content {
        justify-content: flex-end;
    }

    .admin-code-element .section-content img {
        width: 21px;
        margin-right: 10px;
    }

    .admin-code-element .content-value {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .admin-code-element .content-value span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-code-element button.button-copy,
    .admin-code-element button.button-remove {
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 12px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-code-element button.button-copy {
        margin-right: 8px;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .admin-code-element button.button-remove {
        background: #fd3b31;
        border-bottom: 2px solid #97302b;
    }

    @media only screen and (max-width: 1200px) {

        .admin-code-element {
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 20px;
        }

        .admin-code-element .element-section {
            width: 100%!important;
            margin-top: 10px;
        }

        .admin-code-element .element-section.section-name {
            margin-top: 0;
        }

        .admin-code-element .element-section.section-actions {
            align-items: flex-start;
        }

        .admin-code-element .section-title {
            display: block;
        }

        .admin-code-element .section-content {
            margin-top: 5px;
        }

    }
</style>
