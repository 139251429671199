<template>
    <div class="admin-transactions">
        <div class="transactions-content">
            <div class="content-header">
                <div class="header-element element-method">METHOD</div>
                <div class="header-element element-amount">AMOUNT</div>
                <div class="header-element element-state">STATE</div>
                <div class="header-element element-date">DATE</div>
                <div class="header-element element-transaction">TRANSACTION</div>
                <div class="header-element element-actions">ACTIONS</div>
            </div>
            <div class="content-list">
                <transition name="fade" mode="out-in">
                    <div v-if="adminTransactionList.data === null || adminTransactionList.loading === true" class="list-loading" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else-if="adminTransactionList.data.length > 0" class="list-data" key="data">

                        <AdminTransactionElement v-for="transaction in adminTransactionList.data" v-bind:key="transaction._id" v-bind:transaction="transaction" />

                    </div>
                    <div v-else class="list-empty" key="empty">NO TRANSACTIONS FOUND.</div>
                </transition>
            </div>
        </div>

        <Pagination v-on:setPage="adminSetPage" v-bind:page="adminTransactionList.page" v-bind:count="adminTransactionList.count" countPage="14" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import Pagination from '@/components/Pagination';
    import AdminTransactionElement from '@/components/admin/transactions/AdminTransactionElement';

    export default {
        name: 'adminTransactionList',
        components: {
            LoadingAnimation,
            Pagination,
            AdminTransactionElement
        },
        methods: {
            ...mapActions([
                'adminGetTransactionListSocket', 
                'adminSetTransactionListPage', 
                'adminSetFilterSelect',
                'adminSetFilterSearch'
            ]),
            adminSetPage(page) {
                this.adminSetTransactionListPage(page);
                this.adminGetTransactionListSocket({ page: this.adminTransactionList.page, select: this.adminFilterSelect, search: this.adminFilterSearch });
            }
        },
        computed: {
            ...mapGetters([
                'adminTransactionList',
                'adminFilterSelect', 
                'adminFilterSearch'
            ])
        },
        created() {
            if(this.adminTransactionList.loading === false) {
                this.adminGetTransactionListSocket({ page: this.adminTransactionList.page, select: this.adminFilterSelect, search: this.adminFilterSearch });
            }
        },
        beforeRouteLeave(to, from, next) {
            this.adminSetFilterSelect('all');
            this.adminSetFilterSearch('');
            next();
        }
    }
</script>

<style scoped>
    .admin-transactions {
        width: 100%;
    }

    .admin-transactions .transactions-content {
        width: 100%;
        padding: 15px;
        border-radius: 8px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .admin-transactions .content-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .admin-transactions .header-element {
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-transactions .header-element.element-method,
    .admin-transactions .header-element.element-date,
    .admin-transactions .header-element.element-transaction {
        width: 20%;
    }

    .admin-transactions .header-element.element-amount,
    .admin-transactions .header-element.element-state {
        width: 15%;
    }

    .admin-transactions .header-element.element-actions {
        width: 10%;
        display: flex;
        justify-content: flex-end;
    }
    
    .admin-transactions .content-list {
        width: 100%;
        margin-top: 8px;
    }

    .admin-transactions .list-loading {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-transactions .list-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .admin-transactions .list-loading.fade-leave-to {
        opacity: 0;
    }

    .admin-transactions .list-data {
        width: 100%;
    }

    .admin-transactions .list-empty {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-transactions .list-data.fade-enter-active,
    .admin-transactions .list-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .admin-transactions .list-data.fade-enter-from,
    .admin-transactions .list-empty.fade-enter-from {
        opacity: 0;
    }

    @media only screen and (max-width: 1200px) {

        .admin-transactions .transactions-content {
            padding: 10px;
        }

        .admin-transactions .content-header {
            display: none;
        }

        .admin-transactions .content-list {
            width: 100%;
            margin-top: 0;
        }

    }
</style>
