<template>
    <button v-if="adminTicketData.data !== null && adminTicketData.data.state === 'created'" v-on:click="adminCloseButton" class="admin-button-close" v-bind:class="{ 
        'button-loading': socketSendLoading === 'AdminTicketCompleted' 
    }">
        <transition name="fade" mode="out-in">
            <div v-if="socketSendLoading === 'AdminTicketCompleted'" class="button-loading" key="loading">
                <LoadingAnimation />
            </div>
            <div v-else class="button-content" key="content">
                CLOSE TICKET
            </div>
        </transition>
    </button>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';

    export default {
        name: 'AdminButtonCreate',
        components: {
            LoadingAnimation
        },
        methods: {
            ...mapActions([
                'notificationShow', 
                'adminSendTicketCompletedSocket'
            ]),
            adminCloseButton() {
                if(this.socketSendLoading !== null) { return; }

                if(this.adminTicketData.loading === true || this.adminTicketData.data === null) {
                    this.notificationShow({ type: 'error', message: 'Please wait until the ticket loading is completed.' });
                    return;
                }

                const data = { ticketId: this.adminTicketData.data._id };
                this.adminSendTicketCompletedSocket(data);
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'adminTicketData'
            ])
        }
    }
</script>

<style scoped>
    button.admin-button-close {
        width: 140px;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        border-radius: 5px;
        font-size: 13px;
        font-weight: 600;
        color: #ffffff;
        background: #fd3b31;
        border-bottom: 2px solid #97302b;
    }

    button.admin-button-close:hover {
        background: #fe524a;
    }

    button.admin-button-close.button-loading {
        background: #00c74d;
        cursor: not-allowed;
    }

    button.admin-button-close .button-loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.admin-button-close .button-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    button.admin-button-close .button-loading.fade-leave-to {
        opacity: 0;
    }

    @media only screen and (max-width: 950px) {

        button.admin-button-close {
            width: 100%;
            margin-right: 0;
            margin-bottom: 8px;
        }

    }
</style>