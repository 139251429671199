<template>
    <div class="admin-promo-create">
        <div class="create-element">
            <div class="element-title">CODE</div>
            <div class="element-input">
                <input v-model="adminCode" type="text" placeholder="Code..." />
            </div>
        </div>
        <div class="create-element">
            <div class="element-title">CODE REWARD</div>
            <div class="element-input input-reward">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <input v-model="adminReward" v-on:input="adminValidateInput" type="text" placeholder="Code reward..." />
            </div>
        </div>
        <div class="create-element">
            <div class="element-title">CODE TOTAL REDEEMPTIONS</div>
            <div class="element-input">
                <input v-model="adminRedeemptions" type="text" placeholder="Code redeemptions..." />
            </div>
        </div>
        <div class="create-element">
            <div class="element-title">CODE MIN LEVEL</div>
            <div class="element-input">
                <input v-model="adminLevelMin" type="text" placeholder="Code min level..." />
            </div>
        </div>
        <div class="create-button">
            <button v-on:click="adminCreateButton()" v-bind:disabled="socketSendLoading !== null">
                <transition name="fade" mode="out-in">
                    <div v-if="socketSendLoading === 'AdminPromoCreate'" class="button-loading" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else class="button-content" key="content">
                        CREATE CODE
                    </div>
                </transition>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';

    export default {
        name: 'AdminPromoCreate',
        components: {
            LoadingAnimation
        },
        data() {
            return {
                adminCode: '',
                adminReward: '0.00',
                adminRedeemptions: 1,
                adminLevelMin: 0
            }
        },
        methods: {
            ...mapActions([
                'notificationShow', 
                'adminSendPromoCreateSocket'
            ]),
            adminValidateInput() {
                 this.adminReward = this.adminReward.replace(/[^\d.]/g, '');
            },
            adminCreateButton() {
                if(this.socketSendLoading !== null) { return; }

                if(this.adminCode === null || this.adminCode.trim() === '') {
                    this.notificationShow({ type: 'error', message: 'Your entered code is invalid.' });
                    return;
                }

                const reward = Math.floor(Number(this.adminReward).toFixed(2) * 1000);
                this.adminReward = Number(this.adminReward).toFixed(2);

                if(reward === null || isNaN(reward) === true || reward <= 0) {
                    this.notificationShow({type: 'error', message: 'Your entered code reward is invalid.'});
                    return;
                }

                if(this.adminRedeemptions === null || isNaN(this.adminRedeemptions) === true || this.adminRedeemptions <= 0) {
                    this.notificationShow({type: 'error', message: 'Your entered code redeemptions is invalid.'});
                    return;
                }

                if(this.adminLevelMin === null || isNaN(this.adminLevelMin) === true || this.adminLevelMin < 0) {
                    this.notificationShow({type: 'error', message: 'Your entered code min level is invalid.'});
                    return;
                }

                this.adminSendPromoCreateSocket({ 
                    code: this.adminCode,
                    reward: reward, 
                    redeemptions: this.adminRedeemptions,
                    levelMin: this.adminLevelMin
                });
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading'
            ])
        }
    }
</script>

<style scoped>
    .admin-promo-create {
        width: 100%;
        padding: 15px;
        border-radius: 8px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .admin-promo-create .create-element {
        width: 100%;
        margin-top: 18px;
    }

    .admin-promo-create .create-element:first-of-type {
        margin-top: 0;
    }

    .admin-promo-create .element-title {
        width: 100%;
        padding: 0 12px;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .admin-promo-create .element-input {
        width: 100%;
        margin-top: 5px;
    }

    .admin-promo-create .element-input.input-reward {
        position: relative;
    }

    .admin-promo-create .element-input img {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translate(0, -50%);
    }

    .admin-promo-create .element-input input {
        width: 100%;
        height: 54px;
        padding: 0 17px;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 700;
        color: #ffffff;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .admin-promo-create .element-input.input-reward input {
        padding: 0 17px 0 54px;
    }

    .admin-promo-create .element-input input::placeholder {
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .admin-promo-create .create-button {
        widows: 100%;
        margin-top: 18px;
    }

    .admin-promo-create .create-button button {
        width: 100%;
        height: 55px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .admin-promo-create .create-button button:hover {
        background: #00de56;
    }

    .admin-promo-create .create-button button.button-loading {
        background: #00c74d;
        cursor: not-allowed;
    }

    .admin-promo-create .create-button button .button-loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-promo-create .create-button button .button-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .admin-promo-create .create-button button .button-loading.fade-leave-to {
        opacity: 0;
    }

    .admin-promo-create .create-generated {
        widows: 100%;
        margin-top: 24px;
    }

    .admin-promo-create .create-generated textarea {
        width: 100%;
        padding: 12px 17px;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 700;
        color: #ffffff;
        background: #212732;
        resize: none;
    }

    .admin-promo-create .create-generated textarea::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #1c2029;
    }

    .admin-promo-create .create-generated textarea::-webkit-scrollbar {
        width: 8px;
        height: 0;
    }

    .admin-promo-create .create-generated textarea::-webkit-scrollbar-track {
        background: transparent;
    }
</style>
