<template>
    <div class="admin-stats-element">
        <div class="element-title">{{adminFilterStats.toUpperCase() + ' ' + name.toUpperCase()}}</div>
        <div class="element-content">
            <transition name="fade" mode="out-in">
                <div class="content-loading" v-if="value === null" key="loading"></div>
                <div class="content-value" v-else key="value">
                    <span v-if="['users', 'referred', 'participants'].includes(name) === true">{{value}}</span>
                    <div v-else class="value-coins">
                        <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                        <div class="coins-amount" v-bind:class="{ 'amount-negative': value < 0 }">
                            <span>{{generalFormatAmount(value).split('.')[0]}}</span>.{{generalFormatAmount(value).split('.')[1]}}
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import mixins from '@/mixins';

    export default {
        name: 'AdminStatsElement',
        mixins: [
            mixins
        ],
        props: [
            'name', 
            'value'
        ],
        computed: {
            ...mapGetters([
                'adminFilterStats'
            ])
        }
    }
</script>

<style scoped>
    .admin-stats-element {
        padding: 18px;
        border-radius: 8px;
        background: #212732;
    }

    .admin-stats-element .element-title {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-stats-element .element-content {
        width: 100%;
        height: 26px;
        display: flex;
        align-items: center;
        margin-top: 12px;
    }

    .admin-stats-element .content-loading {
        width: 100px;
        height: 20px;
        position: relative;
        margin-top: 14px;
        border-radius: 3px;
        background: #5f6779;
        overflow: hidden;
    }

    .admin-stats-element .content-loading::after {
        width: 100%;
        height: 100%;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        animation-name: loading_animation;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        background: linear-gradient(to right, #ffffff00 0%, rgba(255, 255, 255, .2) 50%, #ffffff00 100%);
        z-index: 1;
    }

    @keyframes loading_animation {
        0% { transform: translateX(-100%); }
        50% { transform: translateX(100%); }
        100% { transform: translateX(100%); }
    }

    .admin-stats-element .content-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .admin-stats-element .content-loading.fade-leave-to {
        opacity: 0;
    }

    .admin-stats-element .content-value {
        display: flex;
        align-items: center;
    }

    .admin-stats-element .content-value span,
    .admin-stats-element .coins-amount span {
        font-size: 17px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-stats-element .value-coins {
        display: flex;
        align-items: center;
    }

    .admin-stats-element .value-coins img {
        width: 21px;
        margin-right: 10px;
    }

    .admin-stats-element .coins-amount {
        font-size: 14px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .admin-stats-element.element-profit .coins-amount {
        color: #00c74d;
    }

    .admin-stats-element.element-profit .coins-amount.amount-negative {
        color: #fd3b31;
    }

    .admin-stats-element.element-profit .coins-amount span {
        color: #00c74d;
    }

    .admin-stats-element.element-profit .coins-amount.amount-negative span {
        color: #fd3b31;
    }
</style>
