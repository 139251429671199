<template>
    <div class="admin-user">
        <AdminUserBanned />

        <div class="user-container">
            <AdminUserOverview />
            <div class="container-info">
                <div class="info-stats">
                    <AdminStatsElement v-bind:name="adminFilterStats === 'affiliates' ? 'generated' : 'wagered'" v-bind:value="adminFilterStats === 'affiliates' ? adminGetGenerated : adminGetBet" />
                    <AdminStatsElement v-bind:name="adminFilterStats === 'affiliates' ? 'referred' : 'deposited'" v-bind:value="adminFilterStats === 'affiliates' ? adminGetReferred: adminGetDeposit" />
                    <AdminStatsElement v-bind:name="adminFilterStats === 'affiliates' ? 'earned' : 'withdrawn'" v-bind:value="adminFilterStats === 'affiliates' ? adminGetEarned : adminGetWithdraw" />
                </div>
                <AdminUserTransactions />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AdminStatsElement from '@/components/admin/AdminStatsElement';
    import AdminUserOverview from '@/components/admin/users/AdminUserOverview';
    import AdminUserTransactions from '@/components/admin/users/AdminUserTransactions';
    import AdminUserBanned from '@/components/admin/users/AdminUserBanned';

    export default {
        name: 'AdminUser',
        components: {
            AdminUserOverview,
            AdminStatsElement,
            AdminUserTransactions,
            AdminUserBanned
        },
        methods: {
            ...mapActions([
                'adminSetFilterSelect',
                'adminSetFilterStats', 
                'adminSetUserTransactionListPage', 
                'adminGetUserDataSocket', 
                'adminGetUserTransactionListSocket'
            ])
        },
        computed: {
            ...mapGetters([
                'adminFilterSelect',
                'adminFilterStats', 
                'adminUserData', 
                'adminUserTransactionList'
            ]),
            adminGetBet() {
                if(this.adminUserData.data !== null && this.adminUserData.loading === false) {
                    return this.adminUserData.data.stats[this.adminFilterStats].bet;
                } else {
                    return null;
                }
            },
            adminGetDeposit() {
                if(this.adminUserData.data !== null && this.adminUserData.loading === false) {
                    return this.adminUserData.data.stats[this.adminFilterStats].deposit;
                } else {
                    return null;
                }
            },
            adminGetWithdraw() {
                if(this.adminUserData.data !== null && this.adminUserData.loading === false) {
                    return this.adminUserData.data.stats[this.adminFilterStats].withdraw;
                } else {
                    return null;
                }
            },
            adminGetGenerated() {
                if(this.adminUserData.data !== null && this.adminUserData.loading === false) {
                    return this.adminUserData.data.affiliates.generated;
                } else {
                    return null;
                }
            },
            adminGetReferred() {
                if(this.adminUserData.data !== null && this.adminUserData.loading === false) {
                    return this.adminUserData.data.affiliates.referred;
                } else {
                    return null;
                }
            },
            adminGetEarned() {
                if(this.adminUserData.data !== null && this.adminUserData.loading === false) {
                    return this.adminUserData.data.affiliates.earned;
                } else {
                    return null;
                }
            }
        },
        created() {
            const userId = this.$route.params.userId;

            if(this.adminUserData.loading === false) {
                const data = { userId: userId, statsAll: false };
                this.adminGetUserDataSocket(data);
            }

            if(this.adminUserTransactionList.loading === false) {
                const data = { userId: userId, select: this.adminFilterSelect, page: this.adminUserTransactionList.page };
                this.adminGetUserTransactionListSocket(data);
            }
        },
        beforeRouteLeave(to, from, next) {
            this.adminSetFilterSelect('all');
            this.adminSetFilterStats('total');
            this.adminSetUserTransactionListPage(1);
            next();
        }
    }
</script>

<style scoped>
    .admin-user {
        width: 100%;
    }

    .admin-user .user-container {
        width: 100%;
        display: flex;
        align-items: flex-start;
    }

    .admin-user .container-info {
        width: calc(100% - 500px);
        padding-left: 25px;
    }

    .admin-user .info-stats {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .admin-user .admin-stats-element {
        width: calc(33.33% - 10.66px);
        margin-right: 16px;
    }

    .admin-user .admin-stats-element:nth-child(3n) {
        margin-right: 0;
    }

    @media only screen and (max-width: 1100px) {

        .admin-user .user-container {
            flex-direction: column;
        }

        .admin-user .container-info {
            width: 100%;
            margin-top: 35px;
            padding-left: 0;
        }

    }

    @media only screen and (max-width: 550px) {

        .admin-user .admin-stats-element {
            width: 100%;
            margin-right: 0;
            margin-bottom: 12px;
        }

        .admin-user .admin-stats-element:last-child {
            margin-bottom: 0;
        }

    }
</style>
