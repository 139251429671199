<template>
    <div class="admin">
        <div class="admin-header">
            <AdminFilterNav />
            <div class="header-filter">
                <AdminButtonClose v-if="adminGetRouteName === 'AdminTicket'" />
                <AdminButtonCreate v-if="adminGetRouteName === 'AdminBoxes' || adminGetRouteName === 'AdminPromo' || adminGetRouteName === 'AdminCodes' || adminGetRouteName === 'AdminLeaderboards' || adminGetRouteName === 'AdminGiveaways' || adminGetRouteName === 'AdminBots'" />
                <AdminButtonBack v-else-if="adminGetRouteName === 'AdminUser' || adminGetRouteName === 'AdminRain' || adminGetRouteName === 'AdminTicket' || adminGetRouteName === 'AdminBoxesCreate' || adminGetRouteName === 'AdminCodesCreate' || adminGetRouteName === 'AdminLeaderboardsCreate' || adminGetRouteName === 'AdminGiveawaysCreate' || adminGetRouteName === 'AdminBotsCreate'" />

                <AdminFilterSort v-if="adminGetRouteName === 'AdminUsers'" />
                <AdminFilterSelect v-if="adminGetRouteName === 'AdminTransactions' || adminGetRouteName === 'AdminUser'" />
                <AdminFilterSearch v-if="adminGetRouteName === 'AdminUsers' || adminGetRouteName === 'AdminTransactions' || adminGetRouteName === 'AdminItems' || adminGetRouteName === 'AdminCodes' || adminGetRouteName === 'AdminGiveaways' || adminGetRouteName === 'AdminTickets' || adminGetRouteName === 'AdminBots'" />
                <AdminFilterStats v-else-if="adminGetRouteName === 'AdminDashboard' || adminGetRouteName === 'AdminUser'" />
            </div>
        </div>
        <div class="admin-content">
            <transition name="slide-fade" mode="out-in">
                <router-view/>
            </transition>
        </div>
    </div>
</template>

<script>
    import AdminFilterNav from '@/components/admin/AdminFilterNav';
    import AdminFilterSort from '@/components/admin/AdminFilterSort';
    import AdminFilterSelect from '@/components/admin/AdminFilterSelect';
    import AdminFilterSearch from '@/components/admin/AdminFilterSearch';
    import AdminFilterStats from '@/components/admin/AdminFilterStats';
    import AdminButtonCreate from '@/components/admin/AdminButtonCreate';
    import AdminButtonClose from '@/components/admin/AdminButtonClose';
    import AdminButtonBack from '@/components/admin/AdminButtonBack';

    export default {
        name: 'Admin',
        metaInfo: {
            title: 'Admin - RustyRocket.GG'
        },
        components: {
            AdminFilterNav,
            AdminFilterSort,
            AdminFilterSelect,
            AdminFilterSearch,
            AdminFilterStats,
            AdminButtonCreate,
            AdminButtonClose,
            AdminButtonBack
        },
        computed: {
            adminGetRouteName() {
                return this.$route.name;
            }
        }
    }
</script>

<style scoped>
    .admin {
        width: 100%;
        min-height: calc(100vh - 112px);
        padding: 40px;
    }

    .admin .admin-header {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
    }

    .admin .header-filter {
        display: flex;
        align-items: center;
    }

    .admin .admin-content {
        width: 100%;
        height: calc(100% - 43px);
        padding-top: 32px;
    }

    .admin .admin-content .slide-fade-enter-active {
        transition: all .3s ease-out;
    }

    .admin .admin-content .slide-fade-enter {
        opacity: 0;
    }

    @media only screen and (max-width: 1850px) {

        .admin {
            padding: 40px 15px 20px 15px;
        }

    }

    @media only screen and (max-width: 950px) {

        .admin .admin-header {
            height: auto;
            flex-direction: column;
        }

        .admin .header-filter {
            width: 100%;
            flex-direction: column;
            margin-top: 16px;
        }

    }
</style>
