<template>
    <div class="admin-promo-element">
        <div class="element-section section-code">
            <div class="section-title">CODE</div>
            <div class="section-content">
                {{promo.code}}
            </div>
        </div>
        <div class="element-section section-reward">
            <div class="section-title">REWARD</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value">
                    <span>{{generalFormatAmount(promo.reward).split('.')[0]}}</span>.{{generalFormatAmount(promo.reward).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-redeemptions">
            <div class="section-title">REDEEMPTIONS</div>
            <div class="section-content">
                {{promo.redeemers.length}}/{{promo.maxRedeemptions}}
            </div>
        </div>
        <div class="element-section section-level">
            <div class="section-title">MIN LEVEL</div>
            <div class="section-content">
                {{promo.levelMin ? promo.levelMin : 0}}
            </div>
        </div>
        <div class="element-section section-actions">
            <div class="section-title">ACTIONS</div>
            <div class="section-content">
                <button v-on:click="adminCopyButton(promo.code.substring(0, 4) + '-' + promo.code.substring(4, 8) + '-' + promo.code.substring(8, 12) + '-' + promo.code.substring(12, 16))" class="button-copy">COPY</button>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapActions } from 'vuex';

    export default {
        name: 'AdminPromoElement',
        mixins: [
            mixins
        ],
        props: [
            'promo'
        ],
        methods: {
            ...mapActions([
                'notificationShow'
            ]),
            adminCopyButton(value) {
                this.generalCopyValue(value);
                this.notificationShow({ type: 'success', message: 'Copied to your clipboard.' });
            }
        }
    }
</script>

<style scoped>
    .admin-promo-element {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .admin-promo-element:nth-child(odd) {
        border-radius: 8px;
        background: #1d212d;
    }

    .admin-promo-element .element-section {
        display: flex;
        flex-direction: column;
    }

    .admin-promo-element .element-section.section-code,
    .admin-promo-element .element-section.section-reward,
    .admin-promo-element .element-section.section-redeemptions,
    .admin-promo-element .element-section.section-level {
        width: 22.5%;
    }

    .admin-promo-element .element-section.section-actions {
        width: 10%;
    }

    .admin-promo-element .section-title {
        display: none;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-promo-element .section-content {
        display: flex;
        align-items: center;
    }

    .admin-promo-element .element-section.section-code .section-content,
    .admin-promo-element .element-section.section-redeemptions .section-content,
    .admin-promo-element .element-section.section-level .section-content {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-promo-element .element-section.section-actions .section-content {
        justify-content: flex-end;
    }

    .admin-promo-element .section-content img {
        width: 21px;
        margin-right: 10px;
    }

    .admin-promo-element .content-value {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .admin-promo-element .content-value span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-promo-element button.button-copy,
    .admin-promo-element button.button-remove {
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 12px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-promo-element button.button-copy {
        margin-right: 8px;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .admin-promo-element button.button-remove {
        background: #fd3b31;
        border-bottom: 2px solid #97302b;
    }

    @media only screen and (max-width: 1200px) {

        .admin-promo-element {
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 20px;
        }

        .admin-promo-element .element-section {
            width: 100%!important;
            margin-top: 10px;
        }

        .admin-promo-element .element-section.section-code {
            margin-top: 0;
        }

        .admin-promo-element .element-section.section-actions {
            align-items: flex-start;
        }

        .admin-promo-element .section-title {
            display: block;
        }

        .admin-promo-element .section-content {
            margin-top: 5px;
        }

    }
</style>