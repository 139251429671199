<template>
    <div class="admin-codes-create">
        <div class="create-element">
            <div class="element-title">CODE NAME</div>
            <div class="element-input">
                <input v-model="adminName" type="text" placeholder="Code name..." />
            </div>
        </div>
        <div class="create-element">
            <div class="element-title">CODE REWARD</div>
            <div class="element-input input-reward">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <input v-model="adminReward" v-on:input="adminValidateInput" type="text" placeholder="Code reward..." />
            </div>
        </div>
        <div class="create-element">
            <div class="element-title">CODE TOTAL REDEEMPTIONS</div>
            <div class="element-input">
                <input v-model="adminRedeemptions" type="text" placeholder="Code redeemptions..." />
            </div>
        </div>
        <div class="create-element">
            <div class="element-title">CODE COUNT</div>
            <div class="element-input">
                <input v-model="adminCount" type="text" placeholder="Code count..." />
            </div>
        </div>
        <div class="create-button">
            <button v-on:click="adminCreateButton" v-bind:class="{ 'button-loading': socketSendLoading === 'AdminCodeCreate' }">
                <transition name="fade" mode="out-in">
                    <div v-if="socketSendLoading === 'AdminCodeCreate'" class="button-loading" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else class="button-content" key="content">
                        CREATE CODE
                    </div>
                </transition>
            </button>
        </div>
        <div v-if="adminCodeList.generated !== null" class="create-generated">
            <textarea v-model="adminGetGenerated" rows="10" disabled></textarea>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';

    export default {
        name: 'AdminCodesCreate',
        components: {
            LoadingAnimation
        },
        data() {
            return {
                adminName: '',
                adminReward: '0.00',
                adminRedeemptions: 1,
                adminCount: 1,
                adminGenerated: null
            }
        },
        methods: {
            ...mapActions(['notificationShow', 'adminSendCodeCreateSocket', 'adminEmptyCodeListGenerated']),
            adminValidateInput() {
                 this.adminReward = this.adminReward.replace(/[^\d.]/g, '');
            },
            adminCreateButton() {
                if(this.socketSendLoading !== null) { return; }

                if(this.adminName === null || this.adminName.trim() === '') {
                    this.notificationShow({ type: 'error', message: 'Your entered code name is invalid.' });
                    return;
                }

                const reward = Math.floor(Number(this.adminReward).toFixed(2) * 1000);
                this.adminReward = Number(this.adminReward).toFixed(2);

                if(reward === null || isNaN(reward) === true || reward <= 0) {
                    this.notificationShow({type: 'error', message: 'Your entered code reward is invalid.'});
                    return;
                }

                if(this.adminRedeemptions === null || isNaN(this.adminRedeemptions) === true || this.adminRedeemptions <= 0) {
                    this.notificationShow({type: 'error', message: 'Your entered code redeemptions is invalid.'});
                    return;
                }

                if(this.adminCount === null || isNaN(this.adminCount) === true || this.adminCount <= 0) {
                    this.notificationShow({type: 'error', message: 'Your entered code count is invalid.'});
                    return;
                }

                const data = { name: this.adminName, reward: reward, redeemptions: this.adminRedeemptions, count: this.adminCount };
                this.adminSendCodeCreateSocket(data);
            }
        },
        computed: {
            ...mapGetters(['socketSendLoading', 'adminCodeList']),
            adminGetGenerated() {
                let string = '';

                for(const generated of this.adminCodeList.generated) {
                    const code = generated.code.substring(0, 4) + '-' + generated.code.substring(4, 8) + '-' + generated.code.substring(8, 12) + '-' + generated.code.substring(12, 16);

                    if(string.length !== 0) {
                        string = string + ',\n' + code;
                    } else {
                        string = code;
                    }
                }

                return string;
            }
        },
        beforeRouteLeave(to, from, next) {
            this.adminEmptyCodeListGenerated();
            next();
        }
    }
</script>

<style scoped>
    .admin-codes-create {
        width: 100%;
        padding: 15px;
        border-radius: 8px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .admin-codes-create .create-element {
        width: 100%;
        margin-top: 18px;
    }

    .admin-codes-create .create-element:first-of-type {
        margin-top: 0;
    }

    .admin-codes-create .element-title {
        width: 100%;
        padding: 0 12px;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .admin-codes-create .element-input {
        width: 100%;
        margin-top: 5px;
    }

    .admin-codes-create .element-input.input-reward {
        position: relative;
    }

    .admin-codes-create .element-input img {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translate(0, -50%);
    }

    .admin-codes-create .element-input input {
        width: 100%;
        height: 54px;
        padding: 0 17px;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 700;
        color: #ffffff;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .admin-codes-create .element-input.input-reward input {
        padding: 0 17px 0 54px;
    }

    .admin-codes-create .element-input input::placeholder {
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .admin-codes-create .create-button {
        widows: 100%;
        margin-top: 18px;
    }

    .admin-codes-create .create-button button {
        width: 100%;
        height: 55px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .admin-codes-create .create-button button:hover {
        background: #00de56;
    }

    .admin-codes-create .create-button button.button-loading {
        background: #00c74d;
        cursor: not-allowed;
    }

    .admin-codes-create .create-button button .button-loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-codes-create .create-button button .button-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .admin-codes-create .create-button button .button-loading.fade-leave-to {
        opacity: 0;
    }

    .admin-codes-create .create-generated {
        widows: 100%;
        margin-top: 24px;
    }

    .admin-codes-create .create-generated textarea {
        width: 100%;
        padding: 12px 17px;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 700;
        color: #ffffff;
        background: #212732;
        resize: none;
    }

    .admin-codes-create .create-generated textarea::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #1c2029;
    }

    .admin-codes-create .create-generated textarea::-webkit-scrollbar {
        width: 8px;
        height: 0;
    }

    .admin-codes-create .create-generated textarea::-webkit-scrollbar-track {
        background: transparent;
    }
</style>
