<template>
    <div class="admin-leaderboards-create">
        <div class="create-element">
            <div class="element-title">LEADERBOARD TYPE</div>
            <div class="element-input">
                <select v-model="adminType">
                    <option value="wager">WAGER</option>
                    <option value="deposit">DEPOSIT</option>
                </select>
            </div>
        </div>
        <div class="create-element">
            <div class="element-title">LEADERBOARD DURATION(DAYS)</div>
            <div class="element-input">
                <input v-model="adminDuration" type="number" placeholder="Leaderboard duration..." />
            </div>
        </div>
        <div class="create-element element-prizes">
            <div class="element-title">LEADERBOARD PRIZES</div>
            <div class="element-content">
                <AdminLeaderboardPrizeElement v-for="(prize, index) in adminPrizes" v-bind:key="index" v-bind:pos="index" v-bind:prize="prize" />
                <button v-on:click="adminAddButton()" class="button-add">ADD PRIZE</button>
            </div>
        </div>
        <div class="create-button">
            <button v-on:click="adminCreateButton()" v-bind:disabled="socketSendLoading !== null">
                <transition name="fade" mode="out-in">
                    <div v-if="socketSendLoading === 'AdminLeaderboardCreate'" class="button-loading" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else class="button-content" key="content">
                        CREATE LEADERBOARD
                    </div>
                </transition>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import AdminLeaderboardPrizeElement from '@/components/admin/leaderboard/AdminLeaderboardPrizeElement';

    export default {
        name: 'AdminLeaderboardsCreate',
        components: {
            LoadingAnimation,
            AdminLeaderboardPrizeElement
        },
        data() {
            return {
                adminType: 'wager',
                adminDuration: null,
                adminPrizes: [
                    { amount: null }
                ]
            }
        },
        methods: {
            ...mapActions([
                'notificationShow', 
                'adminSendLeaderboardCreateSocket'
            ]),
            adminAddButton() {
                this.adminPrizes.push({
                    amount: null
                });
            },
            adminRemoveButton(index) {
                this.adminPrizes.splice(index, 1);
            },
            adminCreateButton() {
                if(this.adminDuration === null || isNaN(this.adminDuration) === true || this.adminDuration <= 1) {
                    this.notificationShow({ type: 'error', message: 'Your entered leaderboard duration is invalid.' });
                    return;
                }

                const prizes = this.adminPrizes.map((element) => ({ amount: Math.floor(element.amount * 1000) }));

                this.adminSendLeaderboardCreateSocket({ type: this.adminType, duration: this.adminDuration, prizes: prizes });
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading'
            ])
        }
    }
</script>

<style scoped>
    .admin-leaderboards-create {
        width: 100%;
        padding: 15px;
        border-radius: 8px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .admin-leaderboards-create .create-element {
        width: 100%;
        margin-top: 18px;
    }

    .admin-leaderboards-create .create-element:first-of-type {
        margin-top: 0;
    }

    .admin-leaderboards-create .element-title {
        width: 100%;
        padding: 0 12px;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .admin-leaderboards-create .element-input {
        width: 100%;
        margin-top: 5px;
    }

    .admin-leaderboards-create .element-input.input-reward {
        position: relative;
    }

    .admin-leaderboards-create .element-input img {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translate(0, -50%);
    }

    .admin-leaderboards-create .element-input input,
    .admin-leaderboards-create .element-input select {
        width: 100%;
        height: 54px;
        padding: 0 17px;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 700;
        color: #ffffff;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .admin-leaderboards-create .element-input.input-reward input {
        padding: 0 17px 0 54px;
    }

    .admin-leaderboards-create .element-input input::placeholder {
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .admin-leaderboards-create .create-button {
        widows: 100%;
        margin-top: 18px;
    }

    .admin-leaderboards-create .create-button button {
        width: 100%;
        height: 55px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .admin-leaderboards-create .create-button button:hover {
        background: #00de56;
    }

    .admin-leaderboards-create .create-button button.button-loading {
        background: #00c74d;
        cursor: not-allowed;
    }

    .admin-leaderboards-create .create-button button .button-loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-leaderboards-create .create-button button .button-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .admin-leaderboards-create .create-button button .button-loading.fade-leave-to {
        opacity: 0;
    }
</style>