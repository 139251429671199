<template>
    <div class="admin-report-element">
        <div class="element-section section-users">
            <div class="section-title">NUC</div>
            <div class="section-content">
                {{adminGetUser}}
            </div>
        </div>
        <div class="element-section section-blackjack">
            <div class="section-title">BLACKJACK</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value" v-bind:class="{ 'value-negative': (adminGetBlackjack.bet - adminGetBlackjack.won) < 0 }">
                    <span>{{generalFormatAmount(adminGetBlackjack.bet - adminGetBlackjack.won).split('.')[0]}}</span>.{{generalFormatAmount(adminGetBlackjack.bet - adminGetBlackjack.won).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-mines">
            <div class="section-title">MINES</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value" v-bind:class="{ 'value-negative': (adminGetMines.bet - adminGetMines.won) < 0 }">
                    <span>{{generalFormatAmount(adminGetMines.bet - adminGetMines.won).split('.')[0]}}</span>.{{generalFormatAmount(adminGetMines.bet - adminGetMines.won).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-towers">
            <div class="section-title">TOWERS</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value" v-bind:class="{ 'value-negative': (adminGetTowers.bet - adminGetTowers.won) < 0 }">
                    <span>{{generalFormatAmount(adminGetTowers.bet - adminGetTowers.won).split('.')[0]}}</span>.{{generalFormatAmount(adminGetTowers.bet - adminGetTowers.won).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-plinko">
            <div class="section-title">PLINKO</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value" v-bind:class="{ 'value-negative': (adminGetPlinko.bet - adminGetPlinko.won) < 0 }">
                    <span>{{generalFormatAmount(adminGetPlinko.bet - adminGetPlinko.won).split('.')[0]}}</span>.{{generalFormatAmount(adminGetPlinko.bet - adminGetPlinko.won).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-upgrader">
            <div class="section-title">UPGRADER</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value" v-bind:class="{ 'value-negative': (adminGetUpgrader.bet - adminGetUpgrader.won) < 0 }">
                    <span>{{generalFormatAmount(adminGetUpgrader.bet - adminGetUpgrader.won).split('.')[0]}}</span>.{{generalFormatAmount(adminGetUpgrader.bet - adminGetUpgrader.won).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-cases">
            <div class="section-title">CASES</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value" v-bind:class="{ 'value-negative': (adminGetCases.bet - adminGetCases.won) < 0 }">
                    <span>{{generalFormatAmount(adminGetCases.bet - adminGetCases.won).split('.')[0]}}</span>.{{generalFormatAmount(adminGetCases.bet - adminGetCases.won).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-battles">
            <div class="section-title">BATTLES</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value" v-bind:class="{ 'value-negative': (adminGetBattles.bet - adminGetBattles.won) < 0 }">
                    <span>{{generalFormatAmount(adminGetBattles.bet - adminGetBattles.won).split('.')[0]}}</span>.{{generalFormatAmount(adminGetBattles.bet - adminGetBattles.won).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-roulette">
            <div class="section-title">ROULETTE</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value" v-bind:class="{ 'value-negative': (adminGetRoulette.bet - adminGetRoulette.won) < 0 }">
                    <span>{{generalFormatAmount(adminGetRoulette.bet - adminGetRoulette.won).split('.')[0]}}</span>.{{generalFormatAmount(adminGetRoulette.bet - adminGetRoulette.won).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-deposited">
            <div class="section-title">DEPOSITED</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value">
                    <span>{{generalFormatAmount(report.stats.total.deposit !== undefined ? report.stats.total.deposit : 0).split('.')[0]}}</span>.{{generalFormatAmount(report.stats.total.deposit !== undefined ? report.stats.total.deposit : 0).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-withdrawn">
            <div class="section-title">WITHDRAWN</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value">
                    <span>{{generalFormatAmount(report.stats.total.withdraw !== undefined ? report.stats.total.withdraw : 0).split('.')[0]}}</span>.{{generalFormatAmount(report.stats.total.withdraw !== undefined ? report.stats.total.withdraw : 0).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-date">
            <div class="section-title">DATE</div>
            <div class="section-content">
                {{new Date(report.createdAt).toLocaleDateString('en-US')}}
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';

    export default {
        name: 'AdminTicketElement',
        mixins: [
            mixins
        ],
        props: [
            'report'
        ],
        computed: {
            adminGetUser() {
                return this.report.stats.total === undefined || this.report.stats.total.user === undefined ? 0 : this.report.stats.total.user;
            },
            adminGetBlackjack() {
                const bet = this.report.stats.blackjack === undefined || this.report.stats.blackjack.bet === undefined ? 0 : this.report.stats.blackjack.bet;
                const won = this.report.stats.blackjack === undefined || this.report.stats.blackjack.won === undefined ? 0 : this.report.stats.blackjack.won;
                return { bet: bet, won: won };
            },
            adminGetMines() {
                const bet = this.report.stats.mines === undefined || this.report.stats.mines.bet === undefined ? 0 : this.report.stats.mines.bet;
                const won = this.report.stats.mines === undefined || this.report.stats.mines.won === undefined ? 0 : this.report.stats.mines.won;
                return { bet: bet, won: won };
            },
            adminGetTowers() {
                const bet = this.report.stats.towers === undefined || this.report.stats.towers.bet === undefined ? 0 : this.report.stats.towers.bet;
                const won = this.report.stats.towers === undefined || this.report.stats.towers.won === undefined ? 0 : this.report.stats.towers.won;
                return { bet: bet, won: won };
            },
            adminGetPlinko() {
                const bet = this.report.stats.plinko === undefined || this.report.stats.plinko.bet === undefined ? 0 : this.report.stats.plinko.bet;
                const won = this.report.stats.plinko === undefined || this.report.stats.plinko.won === undefined ? 0 : this.report.stats.plinko.won;
                return { bet: bet, won: won };
            },
            adminGetUpgrader() {
                const bet = this.report.stats.upgrader === undefined || this.report.stats.upgrader.bet === undefined ? 0 : this.report.stats.upgrader.bet;
                const won = this.report.stats.upgrader === undefined || this.report.stats.upgrader.won === undefined ? 0 : this.report.stats.upgrader.won;
                return { bet: bet, won: won };
            },
            adminGetCases() {
                const bet = this.report.stats.cases === undefined || this.report.stats.cases.bet === undefined ? 0 : this.report.stats.cases.bet;
                const won = this.report.stats.cases === undefined || this.report.stats.cases.won === undefined ? 0 : this.report.stats.cases.won;
                return { bet: bet, won: won };
            },
            adminGetBattles() {
                const bet = this.report.stats.battles === undefined || this.report.stats.battles.bet === undefined ? 0 : this.report.stats.battles.bet;
                const won = this.report.stats.battles === undefined || this.report.stats.battles.won === undefined ? 0 : this.report.stats.battles.won;
                return { bet: bet, won: won };
            },
            adminGetRoulette() {
                const bet = this.report.stats.roulette === undefined || this.report.stats.roulette.bet === undefined ? 0 : this.report.stats.roulette.bet;
                const won = this.report.stats.roulette === undefined || this.report.stats.roulette.won === undefined ? 0 : this.report.stats.roulette.won;
                return { bet: bet, won: won };
            }
        }
    }
</script>

<style scoped>
    .admin-report-element {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .admin-report-element:nth-child(odd) {
        border-radius: 8px;
        background: #1d212d;
    }

    .admin-report-element .element-section {
        display: flex;
        flex-direction: column;
    }

    .admin-report-element .element-section.section-users,
    .admin-report-element .element-section.section-blackjack,
    .admin-report-element .element-section.section-mines,
    .admin-report-element .element-section.section-towers,
    .admin-report-element .element-section.section-plinko,
    .admin-report-element .element-section.section-upgrader,
    .admin-report-element .element-section.section-cases,
    .admin-report-element .element-section.section-battles,
    .admin-report-element .element-section.section-roulette,
    .admin-report-element .element-section.section-deposited,
    .admin-report-element .element-section.section-withdrawn {
        width: 8.18%;
    }

    .admin-report-element .element-section.section-date {
        width: 10%;
    }

    .admin-report-element .section-title {
        display: none;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-report-element .section-content {
        display: flex;
        align-items: center;
    }

    .admin-report-element .element-section.section-users .section-content,
    .admin-report-element .element-section.section-date .section-content {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-report-element .element-section.section-date .section-content {
        justify-content: flex-end;
    }

    .admin-report-element .section-content img {
        width: 21px;
        margin-right: 10px;
    }

    .admin-report-element .content-value {
        font-size: 12px;
        font-weight: 700;
        color: #00c74d;
    }

    .admin-report-element .content-value.value-negative {
        color: #fd3b31;
    }

    .admin-report-element .element-section.section-deposited .content-value,
    .admin-report-element .element-section.section-withdrawn .content-value {
        color: #bbbbbb;
    }

    .admin-report-element .content-value span {
        font-size: 15px;
        font-weight: 700;
        color: #00c74d;
    }

    .admin-report-element .content-value.value-negative span {
        color: #fd3b31;
    }

    .admin-report-element .element-section.section-deposited .content-value span,
    .admin-report-element .element-section.section-withdrawn .content-value span {
        color: #ffffff;
    }

    @media only screen and (max-width: 1200px) {

        .admin-report-element {
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 20px;
        }

        .admin-report-element .element-section {
            width: 100%!important;
            margin-top: 10px;
        }

        .admin-report-element .element-section.section-users {
            margin-top: 0;
        }

        .admin-report-element .element-section.section-date {
            align-items: flex-start;
        }

        .admin-report-element .section-title {
            display: block;
        }

        .admin-report-element .section-content {
            margin-top: 5px;
        }

    }
</style>