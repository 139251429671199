<template>
    <router-link v-bind:to="adminGetRoute" class="admin-button-back" v-bind:class="{
        'back-user': adminGetRouteName === 'AdminUser'
    }">GO BACK</router-link>
</template>

<script>
    export default {
        name: 'AdminButtonBack',
        computed: {
            adminGetRouteName() {
                return this.$route.name;
            },
            adminGetRoute() {
                let route = '/admin';

                if(this.adminGetRouteName === 'AdminUser') { route = '/admin/users'; }
                else if(this.adminGetRouteName === 'AdminRain') { route = '/admin/rains'; }
                else if(this.adminGetRouteName === 'AdminTicket') { route = '/admin/tickets'; }
                else if(this.adminGetRouteName === 'AdminBoxesCreate') { route = '/admin/boxes'; }
                else if(this.adminGetRouteName === 'AdminCodesCreate') { route = '/admin/codes'; }
                else if(this.adminGetRouteName === 'AdminLeaderboardsCreate') { route = '/admin/leaderboards'; }
                else if(this.adminGetRouteName === 'AdminBotsCreate') { route = '/admin/bots'; }

                return route;
            }
        }
    }
</script>

<style scoped>
    a.admin-button-back {
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        border-radius: 5px;
        font-size: 13px;
        font-weight: 600;
        color: #767c8b;
        background: #212732;
        border-bottom: 2px solid #191e27;
    }

    a.admin-button-back.back-user {
        margin-right: 12px;
    }

    a.admin-button-back:hover {
        color: #ffffff;
    }

    @media only screen and (max-width: 950px) {

        a.admin-button-back  {
            width: 100%;
        }

        a.admin-button-back.back-user {
            margin-right: 0;
            margin-bottom: 5px;
        }

    }
</style>