<template>
    <div class="admin-leaderboard-element">
        <div class="element-section section-type">
            <div class="section-title">TYPE</div>
            <div class="section-content">
                {{leaderboard.type.toUpperCase()}}
            </div>
        </div>
        <div class="element-section section-prizes">
            <div class="section-title">PRIZES</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value">
                    <span>{{generalFormatAmount(adminGetPrizes).split('.')[0]}}</span>.{{generalFormatAmount(adminGetPrizes).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-duration">
            <div class="section-title">DURATION</div>
            <div class="section-content">
                {{leaderboard.duration}} DAYS
            </div>
        </div>
        <div class="element-section section-state" v-bind:class="'section-' + leaderboard.state">
            <div class="section-title">STATE</div>
            <div class="section-content">
                {{leaderboard.state.toUpperCase()}}
            </div>
        </div>
        <div class="element-section section-actions">
            <div class="section-title">ACTIONS</div>
            <div class="section-content">
                <button v-if="['created', 'running'].includes(leaderboard.state) === true" v-on:click="adminCancelButton()" class="button-cancel" v-bind:disabled="socketSendLoading !== null">CANCEL</button>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'AdminLeaderboardElement',
        mixins: [
            mixins
        ],
        props: [
            'leaderboard'
        ],
        methods: {
            ...mapActions([
                'adminSendLeaderboardCancelSocket'
            ]),
            adminCancelButton() {
                this.adminSendLeaderboardCancelSocket({ leaderboardId: this.leaderboard._id });
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading'
            ]),
            adminGetPrizes() {
                let prizes = 0;

                for(const winner of this.leaderboard.winners) {  
                    prizes = prizes + winner.prize;
                }

                return prizes;
            }
        }
    }
</script>

<style scoped>
    .admin-leaderboard-element {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .admin-leaderboard-element:nth-child(odd) {
        border-radius: 8px;
        background: #1d212d;
    }

    .admin-leaderboard-element .element-section {
        display: flex;
        flex-direction: column;
    }

    .admin-leaderboard-element .element-section.section-type,
    .admin-leaderboard-element .element-section.section-prizes {
        width: 25%;
    }

    .admin-leaderboard-element .element-section.section-duration,
    .admin-leaderboard-element .element-section.section-state {
        width: 20%;
    }

    .admin-leaderboard-element .element-section.section-actions {
        width: 10%;
    }

    .admin-leaderboard-element .section-title {
        display: none;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-leaderboard-element .section-content {
        display: flex;
        align-items: center;
    }

    .admin-leaderboard-element .element-section.section-type .section-content,
    .admin-leaderboard-element .element-section.section-duration .section-content,
    .admin-leaderboard-element .element-section.section-state .section-content {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-leaderboard-element .element-section.section-state.section-created .section-content,
    .admin-leaderboard-element .element-section.section-state.section-running .section-content {
        color: #f4d03f;
    }

    .admin-leaderboard-element .element-section.section-state.section-completed .section-content {
        color: #00c74d;
    }

    .admin-leaderboard-element .element-section.section-state.section-canceled .section-content {
        color: #fd3b31;
    }

    .admin-leaderboard-element .element-section.section-actions .section-content {
        justify-content: flex-end;
    }

    .admin-leaderboard-element .section-content img {
        width: 21px;
        margin-right: 10px;
    }

    .admin-leaderboard-element .content-value {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .admin-leaderboard-element .content-value span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-leaderboard-element button.button-cancel {
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 12px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
        background: #fd3b31;
        border-bottom: 2px solid #97302b;
    }

    @media only screen and (max-width: 1000px) {

        .admin-leaderboard-element {
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 20px;
        }

        .admin-leaderboard-element .element-section {
            width: 100%!important;
            margin-top: 10px;
        }

        .admin-leaderboard-element .element-section.section-type {
            margin-top: 0;
        }

        .admin-leaderboard-element .element-section.section-actions {
            align-items: flex-start;
        }

        .admin-leaderboard-element .section-title {
            display: block;
        }

        .admin-leaderboard-element .section-content {
            margin-top: 5px;
        }

    }
</style>
