<template>
    <div class="admin-dashboard">
        <div class="dashboard-stats">
            <AdminStatsElement name="users" v-bind:value="adminGetUsers" />
            <AdminStatsElement name="deposited" v-bind:value="adminGetDeposit" />
            <AdminStatsElement name="withdrawn" v-bind:value="adminGetWithdraw" />
            <AdminStatsElement name="profit" v-bind:value="adminGetProfit" class="element-profit" />
        </div>
        <div class="dashboard-settings">

            <AdminSettingsSection title="general">

                <div class="section-element">
                    <div class="element-name">MAINTENANCE</div>
                    <div class="element-action">
                        <button 
                            v-on:click="adminSettingsToggle('general.maintenance.enabled', !generalSettings.general.maintenance.enabled)" 
                            class="button-toggle" 
                            v-bind:class="{ 'button-active': generalSettings.general.maintenance.enabled === true }"
                            v-bind:disabled="socketSendLoading !== null"
                        ></button>
                    </div>
                </div>
                <div class="section-element">
                    <div class="element-name">AFFILIATES REDEEM</div>
                    <div class="element-action">
                        <button 
                            v-on:click="adminSettingsToggle('general.redeem.enabled', !generalSettings.general.redeem.enabled)" 
                            class="button-toggle" 
                            v-bind:class="{ 'button-active': generalSettings.general.redeem.enabled === true }"
                            v-bind:disabled="socketSendLoading !== null"
                        ></button>
                    </div>
                </div>

            </AdminSettingsSection>

            <AdminSettingsSection title="chat">

                <div class="section-element">
                    <div class="element-name">ACTIVE</div>
                    <div class="element-action">
                        <button 
                            v-on:click="adminSettingsToggle('chat.enabled', !generalSettings.chat.enabled)" 
                            class="button-toggle" 
                            v-bind:class="{ 'button-active': generalSettings.chat.enabled === true }"
                            v-bind:disabled="socketSendLoading !== null"
                        ></button>
                    </div>
                </div>
                <div class="section-element">
                    <div class="element-name">SLOW MODE</div>
                    <div class="element-action">
                        <button 
                            v-on:click="adminSettingsToggle('chat.mode', generalSettings.chat.mode === 'slow' ? 'normal' : 'slow')" 
                            class="button-toggle" 
                            v-bind:class="{ 'button-active': generalSettings.chat.mode === 'slow' }"
                            v-bind:disabled="socketSendLoading !== null"
                        ></button>
                    </div>
                </div>

            </AdminSettingsSection>

            <AdminSettingsSection title="games">

                <div class="section-element">
                    <div class="element-name">BLACKJACK</div>
                    <div class="element-action">
                        <button 
                            v-on:click="adminSettingsToggle('games.blackjack.enabled', !generalSettings.games.blackjack.enabled)" 
                            class="button-toggle" 
                            v-bind:class="{ 'button-active': generalSettings.games.blackjack.enabled === true }"
                            v-bind:disabled="socketSendLoading !== null"
                        ></button>
                    </div>
                </div>
                <div class="section-element">
                    <div class="element-name">MINES</div>
                    <div class="element-action">
                        <button 
                            v-on:click="adminSettingsToggle('games.mines.enabled', !generalSettings.games.mines.enabled)" 
                            class="button-toggle" 
                            v-bind:class="{ 'button-active': generalSettings.games.mines.enabled === true }"
                            v-bind:disabled="socketSendLoading !== null"
                        ></button>
                    </div>
                </div>
                <div class="section-element">
                    <div class="element-name">TOWERS</div>
                    <div class="element-action">
                        <button 
                            v-on:click="adminSettingsToggle('games.towers.enabled', !generalSettings.games.towers.enabled)" 
                            class="button-toggle" 
                            v-bind:class="{ 'button-active': generalSettings.games.towers.enabled === true }"
                            v-bind:disabled="socketSendLoading !== null"
                        ></button>
                    </div>
                </div>
                <div class="section-element">
                    <div class="element-name">PLINKO</div>
                    <div class="element-action">
                        <button 
                            v-on:click="adminSettingsToggle('games.plinko.enabled', !generalSettings.games.plinko.enabled)" 
                            class="button-toggle" 
                            v-bind:class="{ 'button-active': generalSettings.games.plinko.enabled === true }"
                            v-bind:disabled="socketSendLoading !== null"
                        ></button>
                    </div>
                </div>
                <div class="section-element">
                    <div class="element-name">UPGRADER</div>
                    <div class="element-action">
                        <button 
                            v-on:click="adminSettingsToggle('games.upgrader.enabled', !generalSettings.games.upgrader.enabled)" 
                            class="button-toggle" 
                            v-bind:class="{ 'button-active': generalSettings.games.upgrader.enabled === true }"
                            v-bind:disabled="socketSendLoading !== null"
                        ></button>
                    </div>
                </div>
                <div class="section-element">
                    <div class="element-name">ROULETTE</div>
                    <div class="element-action">
                        <button 
                            v-on:click="adminSettingsToggle('games.roulette.enabled', !generalSettings.games.roulette.enabled)" 
                            class="button-toggle" 
                            v-bind:class="{ 'button-active': generalSettings.games.roulette.enabled === true }"
                            v-bind:disabled="socketSendLoading !== null"
                        ></button>
                    </div>
                </div>
                <div class="section-element">
                    <div class="element-name">CASES</div>
                    <div class="element-action">
                        <button 
                            v-on:click="adminSettingsToggle('games.cases.enabled', !generalSettings.games.cases.enabled)" 
                            class="button-toggle" 
                            v-bind:class="{ 'button-active': generalSettings.games.cases.enabled === true }"
                            v-bind:disabled="socketSendLoading !== null"
                        ></button>
                    </div>
                </div>
                <div class="section-element">
                    <div class="element-name">BATTLES</div>
                    <div class="element-action">
                        <button 
                            v-on:click="adminSettingsToggle('games.battles.enabled', !generalSettings.games.battles.enabled)" 
                            class="button-toggle" 
                            v-bind:class="{ 'button-active': generalSettings.games.battles.enabled === true }"
                            v-bind:disabled="socketSendLoading !== null"
                        ></button>
                    </div>
                </div>

            </AdminSettingsSection>

            <AdminSettingsSection title="steam">

                <div class="section-element">
                    <div class="element-name">DEPOSIT</div>
                    <div class="element-action">
                        <button 
                            v-on:click="adminSettingsToggle('steam.deposit.enabled', !generalSettings.steam.deposit.enabled)" 
                            class="button-toggle" 
                            v-bind:class="{ 'button-active': generalSettings.steam.deposit.enabled === true }"
                            v-bind:disabled="socketSendLoading !== null"
                        ></button>
                    </div>
                </div>
                <div class="section-element">
                    <div class="element-name">WITHDRAW</div>
                    <div class="element-action">
                        <button 
                            v-on:click="adminSettingsToggle('steam.withdraw.enabled', !generalSettings.steam.withdraw.enabled)" 
                            class="button-toggle" 
                            v-bind:class="{ 'button-active': generalSettings.steam.withdraw.enabled === true }"
                            v-bind:disabled="socketSendLoading !== null"
                        ></button>
                    </div>
                </div>

            </AdminSettingsSection>

            <AdminSettingsSection title="crypto">

                <div class="section-element">
                    <div class="element-name">DEPOSIT</div>
                    <div class="element-action">
                        <button 
                            v-on:click="adminSettingsToggle('crypto.deposit.enabled', !generalSettings.crypto.deposit.enabled)" 
                            class="button-toggle" 
                            v-bind:class="{ 'button-active': generalSettings.crypto.deposit.enabled === true }"
                            v-bind:disabled="socketSendLoading !== null"
                        ></button>
                    </div>
                </div>

            </AdminSettingsSection>

            <AdminSettingsSection title="gift">

                <div class="section-element">
                    <div class="element-name">DEPOSIT</div>
                    <div class="element-action">
                        <button 
                            v-on:click="adminSettingsToggle('gift.deposit.enabled', !generalSettings.gift.deposit.enabled)" 
                            class="button-toggle" 
                            v-bind:class="{ 'button-active': generalSettings.gift.deposit.enabled === true }"
                            v-bind:disabled="socketSendLoading !== null"
                        ></button>
                    </div>
                </div>

            </AdminSettingsSection>

            <AdminSettingsSection title="creditcard">

                <div class="section-element">
                    <div class="element-name">DEPOSIT</div>
                    <div class="element-action">
                        <button 
                            v-on:click="adminSettingsToggle('credit.deposit.enabled', !generalSettings.credit.deposit.enabled)" 
                            class="button-toggle" 
                            v-bind:class="{ 'button-active': generalSettings.credit.deposit.enabled === true }"
                            v-bind:disabled="socketSendLoading !== null"
                        ></button>
                    </div>
                </div>

            </AdminSettingsSection>

        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AdminStatsElement from '@/components/admin/AdminStatsElement';
    import AdminSettingsSection from '@/components/admin/AdminSettingsSection';

    export default {
        name: 'AdminDashboard',
        components: {
            AdminStatsElement,
            AdminSettingsSection
        },
        methods: {
            ...mapActions([
                'adminGetStatsDataSocket', 
                'adminSendSettingsValuelSocket', 
                'adminSetFilterStats'
            ]),
            adminSettingsToggle(setting, value) {
                this.adminSendSettingsValuelSocket({ setting: setting, value: value });
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading', 
                'generalSettings', 
                'adminStatsData', 
                'adminFilterStats'
            ]),
            adminGetUsers() {
                if(this.adminStatsData.data !== null && this.adminStatsData.loading === false) {
                    return this.adminStatsData.data[this.adminFilterStats].users;
                } else {
                    return null;
                }
            },
            adminGetDeposit() {
                if(this.adminStatsData.data !== null && this.adminStatsData.loading === false) {
                    return this.adminStatsData.data[this.adminFilterStats].deposit;
                } else {
                    return null;
                }
            },
            adminGetWithdraw() {
                if(this.adminStatsData.data !== null && this.adminStatsData.loading === false) {
                    return this.adminStatsData.data[this.adminFilterStats].withdraw;
                } else {
                    return null;
                }
            },
            adminGetProfit() {
                if(this.adminStatsData.data !== null && this.adminStatsData.loading === false) {
                    return Math.floor(this.adminStatsData.data[this.adminFilterStats].deposit - this.adminStatsData.data[this.adminFilterStats].withdraw);
                } else {
                    return null;
                }
            }
        },
        created() {
            if(this.adminStatsData.loading === false) { this.adminGetStatsDataSocket({}); }
        },
        beforeRouteLeave(to, from, next) {
            this.adminSetFilterStats('total');
            next();
        }
    }
</script>

<style scoped>
    .admin-dashboard {
        width: 100%;
    }

    .admin-dashboard .dashboard-stats {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .admin-dashboard .admin-stats-element {
        width: calc(25% - 12px);
        margin-right: 16px;
        margin-bottom: 12px;
    }

    .admin-dashboard .admin-stats-element:nth-child(4n) {
        margin-right: 0;
    }

    .admin-dashboard .dashboard-settings {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, auto);
        gap: 10px;
        margin-top: 18px;
    }

    .admin-dashboard .admin-settings-section:nth-child(1) {
        grid-column: 1 / 1;
        grid-row: 1 / 3;
    }

    .admin-dashboard .admin-settings-section:nth-child(2) {
        grid-column: 1 / 1;
        grid-row: 3 / 5;
    }

    .admin-dashboard .admin-settings-section:nth-child(3) {
        grid-column: 2 / 2;
        grid-row: 1 / 5;
    }

    .admin-dashboard .admin-settings-section:nth-child(4) {
        grid-column: 3 / 3;
        grid-row: 1 / 1;
    }

    .admin-dashboard .section-element {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        padding: 8px 10px;
        border-radius: 5px;
        background: #212732;
    }

    .admin-dashboard .section-element:first-of-type {
        margin-top: 0;
    }

    .admin-dashboard .element-name {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
    }

    .admin-dashboard .element-action {
        display: flex;
        align-items: center;
    }

    .admin-dashboard button.button-toggle {
        width: 56px;
        height: 30px;
        position: relative;
    }

    .admin-dashboard button.button-toggle:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 34px;
        background: rgba(253, 59, 49, 0.4);
    }

    .admin-dashboard button.button-toggle.button-active:before {
        background: rgba(0, 199, 77, 0.4);
    }

    .admin-dashboard button.button-toggle:after {
        content: '';
        width: 22px;
        height: 22px;
        position: absolute;
        left: 4px;
        top: 4px;
        border-radius: 50%;
        background: #fd3b31;
        transition: transform 0.3s ease;
    }

    .admin-dashboard button.button-toggle.button-active:after {
        transform: translateX(26px);
        background: #00c74d;
    }

    .admin-dashboard .element-input select {
        height: 30px;
        padding: 0 17px;
        border-radius: 5px;
        font-size: 11px;
        font-weight: 600;
        color: #ffffff;
        background: #191e27;
        border: 1px solid #262c3a;
        cursor: pointer;
    }

    @media only screen and (max-width: 1100px) {

        .admin-dashboard .dashboard-settings {
            grid-template-columns: repeat(3, 1fr);
        }

    }

    @media only screen and (max-width: 1000px) {

        .admin-dashboard .admin-stats-element {
            width: calc(50% - 8px);
        }

        .admin-dashboard .admin-stats-element:nth-child(2n) {
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 850px) {

        .admin-dashboard .dashboard-settings {
            grid-template-columns: repeat(2, 1fr);
        }

        .admin-dashboard .admin-settings-section:nth-child(4) {
            grid-column: 1 / 1;
            grid-row: 5 / 6;
        } 

        .admin-dashboard .admin-settings-section:nth-child(5) {
            grid-column: 1 / 1;
            grid-row: 6 / 7;
        }

        .admin-dashboard .admin-settings-section:nth-child(6) {
            grid-column: 2 / 2;
            grid-row: 5 / 6;
        }

        .admin-dashboard .admin-settings-section:nth-child(7) {
            grid-column: 2 / 2;
            grid-row: 6 / 7;
        }

    }

    @media only screen and (max-width: 600px) {

        .admin-dashboard .dashboard-settings {
            display: flex;
            flex-direction: column;
        }

    }
</style>
