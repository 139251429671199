import { render, staticRenderFns } from "./AdminCodeElement.vue?vue&type=template&id=0497434d&scoped=true"
import script from "./AdminCodeElement.vue?vue&type=script&lang=js"
export * from "./AdminCodeElement.vue?vue&type=script&lang=js"
import style0 from "./AdminCodeElement.vue?vue&type=style&index=0&id=0497434d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0497434d",
  null
  
)

export default component.exports