<template>
    <div class="admin-codes">
        <div class="codes-content">
            <div class="content-header">
                <div class="header-element element-name">NAME</div>
                <div class="header-element element-reward">REWARD</div>
                <div class="header-element element-redeemptions">REDEEMPTIONS</div>
                <div class="header-element element-date">DATE</div>
                <div class="header-element element-actions">ACTIONS</div>
            </div>
            <div class="content-list">
                <transition name="fade" mode="out-in">
                    <div v-if="adminCodeList.data === null || adminCodeList.loading === true" class="list-loading" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else-if="adminCodeList.data.length > 0" class="list-data" key="data">

                        <AdminCodeElement v-for="code in adminCodeList.data" v-bind:key="code._id" v-bind:code="code" />

                    </div>
                    <div v-else class="list-empty" key="empty">NO CODES FOUND.</div>
                </transition>
            </div>
        </div>

        <Pagination v-on:setPage="adminSetPage" v-bind:page="adminCodeList.page" v-bind:count="adminCodeList.count" countPage="14" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import Pagination from '@/components/Pagination';
    import AdminCodeElement from '@/components/admin/codes/AdminCodeElement';

    export default {
        name: 'adminCodeList',
        components: {
            LoadingAnimation,
            Pagination,
            AdminCodeElement
        },
        methods: {
            ...mapActions([
                'adminGetCodeListSocket', 
                'adminSetCodeListPage', 
                'adminSetFilterSearch'
            ]),
            adminSetPage(page) {
                this.adminSetCodeListPage(page);
                this.adminGetCodeListSocket({ page: this.adminCodeList.page, search: this.adminFilterSearch });
            }
        },
        computed: {
            ...mapGetters([
                'adminCodeList', 
                'adminFilterSearch'
            ])
        },
        created() {
            if(this.adminCodeList.loading === false) {
                this.adminGetCodeListSocket({ page: this.adminCodeList.page, search: this.adminFilterSearch });
            }
        },
        beforeRouteLeave(to, from, next) {
            this.adminSetFilterSearch('');
            next();
        }
    }
</script>

<style scoped>
    .admin-codes {
        width: 100%;
    }

    .admin-codes .codes-content {
        width: 100%;
        padding: 15px;
        border-radius: 8px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .admin-codes .content-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .admin-codes .header-element {
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-codes .header-element.element-name {
        width: 30%;
    }

    .admin-codes .header-element.element-reward,
    .admin-codes .header-element.element-redeemptions,
    .admin-codes .header-element.element-actions {
        width: 15%;
    }

    .admin-codes .header-element.element-actions {
        display: flex;
        justify-content: flex-end;
    }

    .admin-codes .header-element.element-date {
        width: 20%;
    }

    .admin-codes .content-list {
        width: 100%;
        margin-top: 8px;
    }

    .admin-codes .list-loading {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-codes .list-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .admin-codes .list-loading.fade-leave-to {
        opacity: 0;
    }

    .admin-codes .list-data {
        width: 100%;
    }

    .admin-codes .list-empty {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-codes .list-data.fade-enter-active,
    .admin-codes .list-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .admin-codes .list-data.fade-enter-from,
    .admin-codes .list-empty.fade-enter-from {
        opacity: 0;
    }

    @media only screen and (max-width: 1200px) {

        .admin-codes .codes-content {
            padding: 10px;
        }

        .admin-codes .content-header {
            display: none;
        }

        .admin-codes .content-list {
            width: 100%;
            margin-top: 0;
        }

    }
</style>
