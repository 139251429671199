<template>
    <div class="admin-leaderboards">
        <div class="leaderboards-content">
            <div class="content-header">
                <div class="header-element element-type">TYPE</div>
                <div class="header-element element-prizes">PRIZES</div>
                <div class="header-element element-duration">DURATION</div>
                <div class="header-element element-state">STATE</div>
                <div class="header-element element-actions">ACTIONS</div>
            </div>
            <div class="content-list">
                <transition name="fade" mode="out-in">
                    <div v-if="adminLeaderboardList.data === null || adminLeaderboardList.loading === true" class="list-loading" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else-if="adminLeaderboardList.data.length > 0" class="list-data" key="data">

                        <AdminLeaderboardElement v-for="leaderboard in adminLeaderboardList.data" v-bind:key="leaderboard._id" v-bind:leaderboard="leaderboard" />

                    </div>
                    <div v-else class="list-empty" key="empty">NO LEADERBOARDS FOUND.</div>
                </transition>
            </div>
        </div>

        <Pagination v-on:setPage="adminSetPage" v-bind:page="adminLeaderboardList.page" v-bind:count="adminLeaderboardList.count" countPage="14" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import Pagination from '@/components/Pagination';
    import AdminLeaderboardElement from '@/components/admin/leaderboard/AdminLeaderboardElement';

    export default {
        name: 'AdminLeaderboards',
        components: {
            LoadingAnimation,
            Pagination,
            AdminLeaderboardElement
        },
        methods: {
            ...mapActions([
                'adminGetLeaderboardListSocket', 
                'adminSetLeaderboardListPage', 
                'adminSetFilterSearch'
            ]),
            adminSetPage(page) {
                this.adminSetLeaderboardListPage(page);
                this.adminGetLeaderboardListSocket({ page: this.adminLeaderboardList.page, search: this.adminFilterSearch });
            }
        },
        computed: {
            ...mapGetters([
                'adminLeaderboardList', 
                'adminFilterSearch'
            ])
        },
        created() {
            if(this.adminLeaderboardList.loading === false) {
                this.adminGetLeaderboardListSocket({ page: this.adminLeaderboardList.page, search: this.adminFilterSearch });
            }
        },
        beforeRouteLeave(to, from, next) {
            this.adminSetFilterSearch('');
            next();
        }
    }
</script>

<style scoped>
    .admin-leaderboards {
        width: 100%;
    }

    .admin-leaderboards .leaderboards-content {
        width: 100%;
        padding: 15px;
        border-radius: 8px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .admin-leaderboards .content-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .admin-leaderboards .header-element {
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-leaderboards .header-element.element-type,
    .admin-leaderboards .header-element.element-prizes {
        width: 25%;
    }

    .admin-leaderboards .header-element.element-duration,
    .admin-leaderboards .header-element.element-state {
        width: 20%;
    }

    .admin-leaderboards .header-element.element-actions {
        width: 10%;
        display: flex;
        justify-content: flex-end;
    }

    .admin-leaderboards .content-list {
        width: 100%;
        margin-top: 8px;
    }

    .admin-leaderboards .list-loading {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-leaderboards .list-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .admin-leaderboards .list-loading.fade-leave-to {
        opacity: 0;
    }

    .admin-leaderboards .list-data {
        width: 100%;
    }

    .admin-leaderboards .list-empty {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-leaderboards .list-data.fade-enter-active,
    .admin-leaderboards .list-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .admin-leaderboards .list-data.fade-enter-from,
    .admin-leaderboards .list-empty.fade-enter-from {
        opacity: 0;
    }

    @media only screen and (max-width: 1000px) {

        .admin-leaderboards .leaderboards-content {
            padding: 10px;
        }

        .admin-leaderboards .content-header {
            display: none;
        }

        .admin-leaderboards .content-list {
            width: 100%;
            margin-top: 0;
        }

    }
</style>