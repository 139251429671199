<template>
    <div class="admin-settings-section">
        <div class="section-title">{{title}}</div>
        <div class="section-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AdminSettingsSection',
        props: [
            'title'
        ]
    }
</script>

<style scoped>
    .admin-settings-section {
        width: 100%;
        padding: 10px;
        border-radius: 8px;
        background: #1C202c;
    }

    .admin-settings-section .section-title {
        padding: 0 4px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-settings-section .section-content {
        width: 100%;
        margin-top: 10px;
    }
</style>
