<template>
    <div class="admin-boxes-create">
        <div class="create-settings">
            <div class="settings-element element-name">
                <div class="element-title">BOX NAME</div>
                <div class="element-input">
                    <input v-model="adminName" type="text" placeholder="Enter name..." />
                </div>
            </div>
            <div class="settings-element element-categories">
                <div class="element-title">BOX CATEGORIES(SPERARATE WITH COMMA)</div>
                <div class="element-input">
                    <input v-model="adminCategories" type="text" placeholder="Enter categories..." />
                </div>
            </div>
            <div class="settings-element element-image">
                <div class="element-title">BOX IMAGE</div>
                <div class="element-input">
                    <input v-model="adminImage" type="text" placeholder="Enter image link..." />
                </div>
            </div>
            <button v-on:click="adminCreateButton()" class="button-create" v-bind:disabled="socketSendLoading === 'AdminCodeCreate'">
                <transition name="fade" mode="out-in">
                    <div v-if="socketSendLoading === 'AdminBoxCreate'" class="button-loading" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else class="button-content" key="content">
                        CREATE BOX
                    </div>
                </transition>
            </button>
        </div>
        <AdminBoxItems />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import AdminBoxItems from '@/components/admin/boxes/AdminBoxItems';

    export default {
        name: 'AdminBoxesCreate',
        components: {
            LoadingAnimation,
            AdminBoxItems
        },
        data() {
            return {
                adminName: null,
                adminImage: null,
                adminCategories: null,
                adminItems: []
            }
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'adminSetFilterSearch',
                'adminGetItemListSocket',
                'adminSendBoxCreateSocket'
            ]),
            adminValidateInput() {
                 this.adminAmount = this.adminAmount.replace(/[^\d.]/g, '');
            },
            adminCreateButton() {
                if(this.adminName === null || this.adminName.trim() === '') {
                    this.notificationShow({ type: 'error', message: 'Your entered box name is invalid.' });
                    return;
                }

                if(this.adminImage === null || this.adminImage.trim() === '') {
                    this.notificationShow({ type: 'error', message: 'Your entered box image is invalid.' });
                    return;
                }

                if(this.adminCategories === null || this.adminCategories.trim() === '') {
                    this.notificationShow({ type: 'error', message: 'Your entered box categories are invalid.' });
                    return;
                }

                const categories = this.adminCategories.split(',').map((element) => element.trim());
                const items = this.adminBoxList.selected.map((element) => ({ item: element._id, tickets: Math.floor(element.percentage * 1000) }));

                if(items.length <= 0 || items.reduce((total, element) => total + element.tickets, 0) !== 100000) {
                    this.notificationShow({ type: 'error', message: 'Your entered items are invalid.' });
                    return;
                }

                this.adminSendBoxCreateSocket({ name: this.adminName, image: this.adminImage, categories: categories, items: items });
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'adminFilterSearch',
                'adminItemList',
                'adminBoxList'
            ])
        },
        created() {
            if(this.adminItemList.loading === false) {
                const data = { page: this.adminItemList.page, search: this.adminFilterSearch };
                this.adminGetItemListSocket(data);
            }
        },
        beforeRouteLeave(to, from, next) {
            this.adminSetFilterSearch('');
            next();
        }
    }
</script>

<style scoped>
    .admin-boxes-create {
        width: 100%;
    }

    .admin-boxes-create .create-settings {
        width: 100%;
        display: grid;
        grid-template-columns: auto 240px;
        grid-template-rows: auto auto;
        grid-column-gap: 15px;
        align-items: flex-end;
        padding: 10px;
        border-radius: 8px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .admin-boxes-create .settings-element {
        width: 100%;
        margin-top: 18px;
    }

    .admin-boxes-create .settings-element.element-name {
        grid-column: 1 / 1;
        grid-row: 1 / 1;
    }

    .admin-boxes-create .settings-element.element-categories {
        grid-column: 1 / 1;
        grid-row: 2 / 2;
    }

    .admin-boxes-create .settings-element.element-image {
        grid-column: 2 / 2;
        grid-row: 1 / 1;
        margin-top: 0;
    }

    .admin-boxes-create .settings-element:first-of-type {
        margin-top: 0;
    }

    .admin-boxes-create .element-title {
        width: 100%;
        padding: 0 12px;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .admin-boxes-create .element-input {
        width: 100%;
        margin-top: 5px;
    }

    .admin-boxes-create .element-input input {
        width: 100%;
        height: 54px;
        padding: 0 17px;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 700;
        color: #ffffff;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .admin-boxes-create .settings-element.element-image .element-input input  {
        padding: 15px 15px;
        font-size: 14px;
        color: #49687d;
    }

    .admin-boxes-create .element-input input::placeholder {
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .admin-boxes-create button.button-create {
        height: 55px;
        grid-column: 2 / 2;
        grid-row: 2 / 2;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .admin-boxes-create button.button-create:hover {
        background: #00de56;
    }

    .admin-boxes-create button.button-create.button-loading {
        background: #00c74d;
        cursor: not-allowed;
    }

    .admin-boxes-create button.button-create .button-loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-boxes-create button.button-create .button-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .admin-boxes-create button.button-create .button-loading.fade-leave-to {
        opacity: 0;
    }

    @media only screen and (max-width: 650px) {

        .admin-boxes-create .create-settings {
            grid-template-columns: auto;
            grid-template-rows: auto auto auto auto;
        }

        .admin-boxes-create .settings-element.element-categories {
            grid-column: 1 / 1;
            grid-row: 3 / 3;
        }

        .admin-boxes-create .settings-element.element-image {
            grid-column: 1 / 1;
            grid-row: 2 / 2;
            margin-top: 18px;
        }

        .admin-boxes-create button.button-create {
            grid-column: 1 / 1;
            grid-row: 4 / 4;
            margin-top: 18px;
        }

    }
</style>