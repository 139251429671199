<template>
    <div class="admin-filter-select" v-bind:class="{ 
        'select-user': adminGetRouteName === 'AdminUser',
        'select-open': adminDropdown === true 
    }">
        <div class="select-dropdown">
            <button class="button-toggle" v-on:click="adminToggleDropdown">
                {{adminFilterSelect.toUpperCase()}} TRANSACTIONS
                <svg width="13" height="9" viewBox="0 0 13 9" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.2142 0.361328L6.5 5.07555L1.78577 0.361328L0 2.1471L6.5 8.6471L13 2.1471L11.2142 0.361328Z" />
                </svg>
            </button>
            <transition name="slide">
                <div class="dropdown-menu" v-if="adminDropdown === true">
                    <div class="menu-inner">
                        <button v-on:click="adminSetValue('all')">ALL TRANSACTIONS</button>
                        <button v-on:click="adminSetValue('steam')">STEAM TRANSACTIONS</button>
                        <button v-on:click="adminSetValue('crypto')">CRYPTO TRANSACTIONS</button>
                        <button v-on:click="adminSetValue('gift')">GIFT TRANSACTIONS</button>
                        <button v-on:click="adminSetValue('credit')">CREDIT TRANSACTIONS</button>
                        <button v-if="adminGetRouteName === 'AdminUser'" v-on:click="adminSetValue('blackjack')">BLACKJACK TRANSACTIONS</button>
                        <button v-if="adminGetRouteName === 'AdminUser'" v-on:click="adminSetValue('mines')">MINES TRANSACTIONS</button>
                        <button v-if="adminGetRouteName === 'AdminUser'" v-on:click="adminSetValue('towers')">TOWERS TRANSACTIONS</button>
                        <button v-if="adminGetRouteName === 'AdminUser'" v-on:click="adminSetValue('plinko')">PLINKO TRANSACTIONS</button>
                        <button v-if="adminGetRouteName === 'AdminUser'" v-on:click="adminSetValue('upgrader')">UPGRADER TRANSACTIONS</button>
                        <button v-if="adminGetRouteName === 'AdminUser'" v-on:click="adminSetValue('balance')">BALANCE TRANSACTIONS</button>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'AdminFilterSelect',
        data() {
            return {
                adminDropdown: false
            }
        },
        methods: {
            ...mapActions([
                'adminSetFilterSelect', 
                'adminSetTransactionListPage',
                'adminSetUserTransactionListPage', 
                'adminGetTransactionListSocket',
                'adminGetUserTransactionListSocket'
            ]),
            adminToggleDropdown() {
                this.adminDropdown = !this.adminDropdown;
            },
            adminSetValue(value) {
                this.adminSetFilterSelect(value);
                this.adminToggleDropdown();

                if(this.adminGetRouteName === 'AdminTransactions') {
                    this.adminSetTransactionListPage(1);

                    const data = { page: 1, select: this.adminFilterSelect, search: this.adminFilterSearch };
                    this.adminGetTransactionListSocket(data);
                } else if(this.adminGetRouteName === 'AdminUser') {
                    this.adminSetUserTransactionListPage(1);

                    const data = { userId: this.adminUserData.data._id, page: 1, select: this.adminFilterSelect };
                    this.adminGetUserTransactionListSocket(data);
                }
            }
        },
        computed: {
            ...mapGetters([
                'adminFilterSelect', 
                'adminFilterSearch',
                'adminUserData'
            ]),
            adminGetRouteName() {
                return this.$route.name;
            }
        },
        mounted() {
            let self = this;
            document.addEventListener('click', function(event) {
                if(!self.$el.contains(event.target) && self.adminDropdown == true) {
                    self.adminToggleDropdown();
                }
            });
        }
    }
</script>

<style scoped>
    .admin-filter-select {
        margin-right: 12px;
    }

    .admin-filter-select .select-dropdown {
        position: relative;
    }

    .admin-filter-select .select-dropdown button.button-toggle {
        height: 46px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 18px;
        border-radius: 5px;
        font-size: 13px;
        font-weight: 600;
        color: #626c7e;
        background: #212732;
        border-bottom: 2px solid #191e27;
    }

    .admin-filter-select .select-dropdown button.button-toggle:hover {
        color: #ffffff;
    }

    .admin-filter-select .select-dropdown button.button-toggle svg {
        margin-left: 12px;
        fill: #626c7e;
        transition: all 0.3s ease;
    }

    .admin-filter-select.select-open .select-dropdown button.button-toggle svg {
        transform: rotate(180deg);
    }

    .admin-filter-select .dropdown-menu {
        width: 100%;
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 10;
    }

    .admin-filter-select .dropdown-menu.slide-enter-active {
        overflow: hidden;
        transition: height 0.2s ease;
    }

    .admin-filter-select .dropdown-menu.slide-enter-active.slide-enter-to,
    .admin-filter-select .dropdown-menu.slide-enter-active.slide-leave {
        height: 187px;
    }

    .admin-filter-select.select-user .dropdown-menu.slide-enter-active.slide-enter-to,
    .admin-filter-select.select-user .dropdown-menu.slide-enter-active.slide-leave {
        height: 397px;
    }

    .admin-filter-select .dropdown-menu.slide-enter-active.slide-enter,
    .admin-filter-select .dropdown-menu.slide-enter-active.slide-leave-to {
        height: 0;
    }

    .admin-filter-select .menu-inner {
        width: 100%;
        padding: 5px 0;
        border-radius: 5px;
        background: #212732;
        border: 1px solid #191e27;
    }

    .admin-filter-select .menu-inner button {
        width: 100%;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
        background: transparent;
    }

    .admin-filter-select .menu-inner button:hover {
        background: #191d26;
    }

    @media only screen and (max-width: 950px) {

        .admin-filter-select  {
            width: 100%;
            margin-right: 0;
            margin-bottom: 5px;
        }

        .admin-filter-select .select-dropdown button.button-toggle {
            width: 100%;
        }

    }
</style>