<template>
    <div class="admin-users">
        <div class="users-content">
            <div class="content-header">
                <div class="header-element element-user">USER</div>
                <div class="header-element element-balance">BALANCE</div>
                <div class="header-element element-wagered">WAGERED</div>
                <div class="header-element element-deposited">DEPOSITED</div>
                <div class="header-element element-withdrawn">WITHDRAWN</div>
                <div class="header-element element-actions">ACTIONS</div>
            </div>
            <div class="content-list">
                <transition name="fade" mode="out-in">
                    <div v-if="adminUserList.data === null || adminUserList.loading === true" class="list-loading" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else-if="adminUserList.data.length > 0" class="list-data" key="data">

                        <AdminUserElement v-for="user in adminUserList.data" v-bind:key="user._id" v-bind:user="user" />

                    </div>
                    <div v-else class="list-empty" key="empty">NO USERS FOUND.</div>
                </transition>
            </div>
        </div>

        <Pagination v-on:setPage="adminSetPage" v-bind:page="adminUserList.page" v-bind:count="adminUserList.count" countPage="14" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import Pagination from '@/components/Pagination';
    import AdminUserElement from '@/components/admin/users/AdminUserElement';

    export default {
        name: 'adminUserList',
        components: {
            LoadingAnimation,
            Pagination,
            AdminUserElement
        },
        methods: {
            ...mapActions([
                'adminGetUserListSocket', 
                'adminSetUserListPage', 
                'adminSetFilterSort', 
                'adminSetFilterSearch'
            ]),
            adminSetPage(page) {
                this.adminSetUserListPage(page);
                this.adminGetUserListSocket({ page: this.adminUserList.page, sort: this.adminFilterSort, search: this.adminFilterSearch });
            }
        },
        computed: {
            ...mapGetters([
                'adminUserList', 
                'adminFilterSort', 
                'adminFilterSearch'
            ])
        },
        created() {
            if(this.adminUserList.loading === false) {
                this.adminGetUserListSocket({ page: this.adminUserList.page, sort: this.adminFilterSort, search: this.adminFilterSearch });
            }
        },
        beforeRouteLeave(to, from, next) {
            this.adminSetFilterSort('newest');
            this.adminSetFilterSearch('');
            next();
        }
    }
</script>

<style scoped>
    .admin-users {
        width: 100%;
    }

    .admin-users .users-content {
        width: 100%;
        padding: 15px;
        border-radius: 8px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .admin-users .content-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .admin-users .header-element {
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-users .header-element.element-user {
        width: 30%;
    }

    .admin-users .header-element.element-balance,
    .admin-users .header-element.element-wagered,
    .admin-users .header-element.element-deposited,
    .admin-users .header-element.element-withdrawn {
        width: 15%;
    }

    .admin-users .header-element.element-actions {
        width: 10%;
        display: flex;
        justify-content: flex-end;
    }

    .admin-users .content-list {
        width: 100%;
        margin-top: 8px;
    }

    .admin-users .list-loading {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-users .list-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .admin-users .list-loading.fade-leave-to {
        opacity: 0;
    }

    .admin-users .list-data {
        width: 100%;
    }

    .admin-users .list-empty {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-users .list-data.fade-enter-active,
    .admin-users .list-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .admin-users .list-data.fade-enter-from,
    .admin-users .list-empty.fade-enter-from {
        opacity: 0;
    }

    @media only screen and (max-width: 1300px) {

        .admin-users .users-content {
            padding: 10px;
        }

        .admin-users .content-header {
            display: none;
        }

        .admin-users .content-list {
            width: 100%;
            margin-top: 0;
        }

    }
</style>
