<template>
    <div v-if="adminUserData.data !== null && adminTimer !== '00:00:00'" class="admin-user-banned">
        User was banned <span>{{adminUserData.data.ban.type}}</span> for <span>{{adminUserData.data.ban.reason}}</span>. Expires in: <span>{{adminTimer}}</span>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'AdminUserBanned',
        data() {
            return {
                adminTimer: '00:00:00',
                adminTimerInterval: null
            }
        },
        methods: {
            adminStartTimer() {
                let timeLeft = Math.floor((new Date(this.adminUserData.data.ban.expire).getTime() - new Date().getTime() + this.generalTimeDiff) / 1000);
                timeLeft = timeLeft <= 0 ? 0 : timeLeft;

                let timeHours = Math.floor(timeLeft / (60 * 60));
                timeHours = timeHours < 10 ? '0' + timeHours : timeHours;
                let timeMinutes = Math.floor((timeLeft % (60 * 60)) / 60);
                timeMinutes = timeMinutes < 10 ? '0' + timeMinutes : timeMinutes;
                let timeSeconds = Math.floor(timeLeft % 60);
                timeSeconds = timeSeconds < 10 ? '0' + timeSeconds : timeSeconds;

                this.adminTimer = timeHours.toString() + ':' + timeMinutes.toString() + ':' + timeSeconds.toString();
            }
        },
        computed: {
            ...mapGetters([
                'generalTimeDiff', 
                'adminUserData'
            ]),
        },
        watch: {
            'adminUserData.data': {
                deep: true,
                handler(data, dataOld) {
                    if(
                        this.adminUserData.data !== null &&
                        this.adminUserData.data.ban !== undefined && 
                        new Date(this.adminUserData.data.ban.expire).getTime() > new Date().getTime() + this.generalTimeDiff
                    ) { 
                        this.adminTimerInterval = setInterval(() => { this.adminStartTimer(); }, 500); 
                    }
                }
            }
        },
        beforeDestroy() {
            clearInterval(this.adminTimerInterval);
        },
        created() {
            if(
                this.adminUserData.data !== null && 
                this.adminUserData.data.ban !== undefined && 
                new Date(this.adminUserData.data.ban.expire).getTime() > new Date().getTime() + this.generalTimeDiff
            ) { 
                this.adminTimerInterval = setInterval(() => { this.adminStartTimer(); }, 500); 
            }
        }
    }
</script>

<style scoped>
    .admin-user-banned {
        width: 100%;
        margin-bottom: 15px;
        padding: 16px 16px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        background: #fd3b31;
        border: 1px solid #be2c25;
    }

    .admin-user-banned span {
        font-weight: 700;
    }
</style>