<template>
    <div class="admin-user-element">
        <div class="element-section section-user">
            <div class="section-title">USERNAME</div>
            <div class="section-content">
                <AvatarImage v-bind:image="user.avatar" />
                <span v-html="user.username"></span>
                <span class="username-rank" v-bind:class="{ 'rank-mod': user.rank === 'mod', 'rank-admin': user.rank === 'admin' }">{{user.rank.toUpperCase()}}</span>
            </div>
        </div>
        <div class="element-section section-balance">
            <div class="section-title">BALANCE</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value">
                    <span>{{generalFormatAmount(user.balance).split('.')[0]}}</span>.{{generalFormatAmount(user.balance).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-wagered">
            <div class="section-title">WAGERED</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value">
                    <span>{{generalFormatAmount(user.stats.total.bet).split('.')[0]}}</span>.{{generalFormatAmount(user.stats.total.bet).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-deposited">
            <div class="section-title">DEPOSITED</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value">
                    <span>{{generalFormatAmount(user.stats.total.deposit).split('.')[0]}}</span>.{{generalFormatAmount(user.stats.total.deposit).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-withdrawn">
            <div class="section-title">WITHDRAWN</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value">
                    <span>{{generalFormatAmount(user.stats.total.withdraw).split('.')[0]}}</span>.{{generalFormatAmount(user.stats.total.withdraw).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-actions">
            <div class="section-title">ACTIONS</div>
            <div class="section-content">
                <router-link v-bind:to="'/admin/users/' + user._id" class="link-view">VIEW</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import AvatarImage from '@/components/AvatarImage';

    export default {
        name: 'AdminUserElement',
        components: {
            AvatarImage
        },
        mixins: [
            mixins
        ],
        props: [
            'user'
        ]
    }
</script>

<style scoped>
    .admin-user-element {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .admin-user-element:nth-child(odd) {
        border-radius: 8px;
        background: #1d212d;
    }

    .admin-user-element .element-section {
        display: flex;
        flex-direction: column;
    }

    .admin-user-element .element-section.section-user {
        width: 30%;
    }

    .admin-user-element .element-section.section-balance,
    .admin-user-element .element-section.section-wagered,
    .admin-user-element .element-section.section-deposited,
    .admin-user-element .element-section.section-withdrawn {
        width: 15%;
    }

    .admin-user-element .element-section.section-actions {
        width: 10%;
    }

    .admin-user-element .section-title {
        display: none;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-user-element .section-content {
        display: flex;
        align-items: center;
    }

    .admin-user-element .element-section.section-user .section-content {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-user-element .element-section.section-actions .section-content {
        justify-content: flex-end;
    }

    .admin-user-element .avatar-image {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        border-radius: 5px;
    }

    .admin-user-element span.username-rank {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .admin-user-element span.username-rank.rank-mod {
        color: #00c74d;
    }

    .admin-user-element span.username-rank.rank-admin {
        color: #fd3b31;
    }

    .admin-user-element .element-section.section-balance .section-content img,
    .admin-user-element .element-section.section-wagered .section-content img,
    .admin-user-element .element-section.section-deposited .section-content img,
    .admin-user-element .element-section.section-withdrawn .section-content img {
        width: 21px;
        margin-right: 10px;
    }

    .admin-user-element .content-value {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .admin-user-element .content-value span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-user-element a.link-view {
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 12px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .admin-user-element a.link-view:hover {
        background: #00de56;
    }

    @media only screen and (max-width: 1200px) {

        .admin-user-element {
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 20px;
        }

        .admin-user-element .element-section {
            width: 100%!important;
            margin-top: 10px;
        }

        .admin-user-element .element-section.section-user {
            margin-top: 0;
        }

        .admin-user-element .element-section.section-actions {
            align-items: flex-start;
        }

        .admin-user-element .section-title {
            display: block;
        }

        .admin-user-element .section-content {
            margin-top: 5px;
        }

    }
</style>
