<template>
    <div class="admin-user-transaction-element">
        <div class="element-section section-method">
            <div class="section-title">METHOD</div>
            <div class="section-content">
                {{adminGetMethod.toUpperCase()}}
            </div>
        </div>
        <div class="element-section section-amount">
            <div class="section-title">AMOUNT</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value" v-bind:class="{ 'value-negative': adminGetAmount < 0 }">
                    <span>{{generalFormatAmount(adminGetAmount).split('.')[0]}}</span>.{{generalFormatAmount(adminGetAmount).split('.')[1]}}
                </div>
                <div class="content-balance">({{generalFormatAmount(transaction.stats.balanceBefore)}})</div>
            </div>
        </div>
        <div class="element-section section-state" v-bind:class="'section-' + adminGetState">
            <div class="section-title">STATE</div>
            <div class="section-content">
                {{adminGetState.toUpperCase()}}
            </div>
        </div>
        <div class="element-section section-date">
            <div class="section-title">DATE</div>
            <div class="section-content">
                {{new Date(transaction.createdAt).toLocaleString('en-US', { hour12: true })}}
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';

    export default {
        name: 'AdminUserTransactionElement',
        mixins: [
            mixins
        ],
        props: [
            'transaction'
        ],
        computed: {
            adminGetMethod() {
                let method = this.transaction.method + ' BET';

                if(['steam', 'crypto', 'gift', 'credit'].includes(this.transaction.method) === true) {
                    method = (this.transaction.method === 'crypto' ? this.transaction.data.currency : this.transaction.method) + ' ' + this.transaction.type;
                } else if(this.transaction.type === 'rakebackClaim') {
                    method = 'RAKEBACK EARNINGS';
                } else if(this.transaction.method === 'balance') {
                    method = this.transaction.type + ' TRANSACTION';
                }

                return method;
            },
            adminGetAmount() {
                if((['steam', 'crypto', 'gift', 'credit'].includes(this.transaction.method) === true && this.transaction.type === 'deposit') || this.transaction.method === 'balance') {
                    return this.transaction.amount;
                } else if(this.transaction.method === 'steam' && ['withdraw', 'upgrader'].includes(this.transaction.type) === true) {
                    return -this.transaction.amount;
                } else {
                    let amount = this.transaction.amount;

                    if(this.transaction.method === 'blackjack') {
                        amount = this.transaction.actions.includes('split') === true || this.transaction.actions.includes('double') === true ? Math.floor(amount * 2) : amount;
                        amount = this.transaction.actions.includes('insurance') ? amount + Math.floor(this.transaction.amount * 0.5) : amount;
                    }

                    return Math.floor(this.transaction.payout - amount);
                }
            },
            adminGetState() {
                let state = this.transaction.state;

                if(['battles', 'roulette'].includes(this.transaction.method) === true) { state = 'completed'; }

                return state;
            }
        }
    }
</script>

<style scoped>
    .admin-user-transaction-element {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .admin-user-transaction-element:nth-child(odd) {
        border-radius: 8px;
        background: #1d212d;
    }

    .admin-user-transaction-element .element-section {
        display: flex;
        flex-direction: column;
    }

    .admin-user-transaction-element .element-section.section-method,
    .admin-user-transaction-element .element-section.section-date {
        width: 25%;
    }

    .admin-user-transaction-element .element-section.section-amount {
        width: 30%;
    }

    .admin-user-transaction-element .element-section.section-state {
        width: 20%;
    }

    .admin-user-transaction-element .section-title {
        display: none;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-user-transaction-element .section-content {
        display: flex;
        align-items: center;
    }

    .admin-user-transaction-element .element-section.section-method .section-content,
    .admin-user-transaction-element .element-section.section-state .section-content,
    .admin-user-transaction-element .element-section.section-date .section-content {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-user-transaction-element .element-section.section-state.section-created .section-content,
	.admin-user-transaction-element .element-section.section-state.section-sent .section-content	{
        color: #f4d03f;
    }
	
    .admin-user-transaction-element .element-section.section-state.section-completed .section-content {
        color: #00c74d;
    }

    .admin-user-transaction-element .element-section.section-state.section-canceled .section-content,
    .admin-user-transaction-element .element-section.section-state.section-failed .section-content {
        color: #fd3b31;
    }

    .admin-user-transaction-element .element-section.section-date .section-content {
        justify-content: flex-end;
    }

    .admin-user-transaction-element .section-content img {
        width: 21px;
        margin-right: 10px;
    }

    .admin-user-transaction-element .content-value {
        font-size: 12px;
        font-weight: 700;
        color: #00c74d;
    }

    .admin-user-transaction-element .content-value.value-negative {
        color: #fd3b31;
    }

    .admin-user-transaction-element .content-value span {
        font-size: 15px;
        font-weight: 700;
    }

    .admin-user-transaction-element .content-balance {
        margin-left: 5px;
        font-size: 11px;
        font-weight: 700;
        color: #ffffff;
    }

    @media only screen and (max-width: 1400px) {

        .admin-user-transaction-element {
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 20px;
        }

        .admin-user-transaction-element .element-section {
            width: 100%!important;
            margin-top: 10px;
        }

        .admin-user-transaction-element .element-section.section-method {
            margin-top: 0;
        }

        .admin-user-transaction-element .element-section.section-date {
            align-items: flex-start;
        }

        .admin-user-transaction-element .section-title {
            display: block;
        }

        .admin-user-transaction-element .section-content {
            margin-top: 5px;
        }

    }
</style>
